import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Loading from "./components/loading";
import {determineLocation} from "./assets/determineLocation";
import {Container} from "@mui/material";
import Receipt from "./Receipt";
import ResponsiveAppBar from "./components/responsive-app-bar";
import Spacer from "./components/Spacer";
import {RenewalStepFlowDisplay} from "./components/renewal-step-flow-display";
import Footer from "./components/home/Footer";
import Store from "./store";
import NoFurtherRenewals from "./components/no-further-renewals";

const Complete = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const paymentSecret = searchParams.get("payment_intent_client_secret");
    const navigate = useNavigate();

    const [confirmed, setConfirmed] = useState(false);

    React.useEffect(() => {
        //We need to just reroute now to the we aren't doing things page. and cancel the order directly.

        fetch(`${determineLocation()}/billing/payment/complete/${paymentSecret}`, {method: 'POST'})
            .then(x => x.json().then((json)=>{
                if(x.status == 200){
                    // navigate(`/receipt?receipt_id=${json.message}`)
                    setSearchParams({receipt:json.message})
                    window.history.replaceState(null, "Receipt", `/receipt?receipt=${json.message}`);
                    setConfirmed(true)

                    Store.setRenewStep(3)
                }else{
                    console.log("failed to get receipt");
                }
            }))
    });

    return <NoFurtherRenewals/>

    if (!confirmed) {
        return <Loading/>
    }

    return (<>
        <ResponsiveAppBar showLogin={false}/>
        <Container maxWidth={"md"}>
            <Spacer height={50}/>
            <RenewalStepFlowDisplay/>
            <Spacer height={20}/>
            <Receipt/>
        </Container>
        <Spacer height={250}/>
        <Footer/>

    </>);

}

export default Complete