import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, {useState} from "react";
import {DarkButton, LightButton} from "./buttons";
import {shadow} from "../assets/colors";
import {determineLocation} from "../assets/determineLocation";

export interface CancelDialogProps {
    open: boolean;
    onClose: () => void;
    receiptNumber: string | null;
    receiptStatus: string | null;
}

export function CancelDialog(props: CancelDialogProps) {
    const {onClose, open, receiptNumber, receiptStatus} = props;

    const handleClose = () => {
        if (loading) {
            return;
        }
        onClose();
    };

    const [loading, setLoading] = useState(false);
    const handleCancel = () => {
        setLoading(true);
        fetch(`${determineLocation()}/billing/payment/cancel/${receiptNumber}`, {method: 'POST'})
            .then(x => {
                    if (x.status == 204) {
                        window.location.reload();
                    }
                }
            );
    }

    return (
        <Dialog slotProps={{backdrop: {style: {backgroundColor: shadow}}}} onClose={handleClose} open={open}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            {receiptStatus == "pending" || receiptStatus == "failed" ? <>
                <DialogTitle>Confirm your want to cancel your order.</DialogTitle>
                <DialogContent>
                    <Typography>Your original form of payment will be refunded and you will receive an email confirming
                        your
                        order is cancelled.</Typography>
                </DialogContent>

                <DialogActions>
                    <DarkButton disabled={loading} onClick={handleCancel} variant={'contained'}
                                sx={{p: 2, mt: 3}}>{(loading ?
                        <CircularProgress size={20}/> : "Cancel Order")}</DarkButton>
                    <LightButton disabled={loading} onClick={handleClose} autoFocus variant={'contained'}
                                 sx={{p: 2, mt: 3}}>Nevermind</LightButton>
                </DialogActions>
            </> : <>
                <DialogTitle>Your order is already being processed.</DialogTitle>
                <DialogContent>
                    <Typography>At this time your order has already begun being processed or has completed it's process
                        and is not able to be canceled.</Typography>
                </DialogContent>

                <DialogActions>
                    <LightButton disabled={loading} onClick={handleClose} autoFocus variant={'contained'}
                                 sx={{p: 2, mt: 3}}>Okay</LightButton>
                </DialogActions>
            </>}

        </Dialog>
    );
}