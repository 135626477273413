import {Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Store from "../store";
import {getFetch} from "../api/client";
import DashboardStore from "../stores/dashboard-store";
import {observer} from "mobx-react";
import Spacer from "../components/Spacer";

export interface CompanyMenuItemProps {
    id: string;
    name: string;
}

interface CompanyStatusMenuItemProps{
    id: string;
    status: string;
    annualReport: string;
}

const CompanySelector = () => {
    const [open, setOpen] = React.useState(false);
    const [companyList, setCompanyList] = useState<CompanyMenuItemProps[]>([]);
    const [companyStatusList, setCompanyStatusList] = useState<CompanyStatusMenuItemProps[]>([]);

    const handleChange = (event: SelectChangeEvent<string | number>) => {
        console.log(event.target.value);
        DashboardStore.setSelectedCompany(event.target.value)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        DashboardStore.setLoading('companySelector', true);
        getFetch({authenticate: true, endpoint: "api/user/company"}).then(async (r) => {
            await r.json().then((body) => {
                setCompanyList(body.message);
                DashboardStore.setLoading('companySelector', false);
                DashboardStore.setSelectedCompany(body.message[0]['id']);
            })
        })
    }, [])

    useEffect(() => {
        companyList.forEach((company)=>{
            getFetch({
                endpoint: `company/${company.id}/status`,
            }).then((r) => {
                r.json().then((body) => {
                    let companyStatus: CompanyStatusMenuItemProps = {
                        id: company.id,
                        status: 'Active',
                        annualReport: ''
                    };
                    // setAnnualReportStatusResponse(r.status);
                    switch (r.status) {
                        case 202:
                            // setAnnualReportStatus(body.message.status);
                            companyStatus.annualReport = body.message.status;
                            // setCurrentAnnualReportReceipt(body.message.id);
                            break;
                        default:
                            // setAnnualReportStatus("Not Filed");
                            companyStatus.annualReport = "Not Filed";
                            break;
                    }

                    //Update the state now
                    let existingStatusList = companyStatusList;
                    let updated = false;
                    existingStatusList.forEach((existingStatusList, index)=>
                    {
                        if(existingStatusList.id === company.id){
                            existingStatusList[index] = companyStatus;
                            updated = true;
                        }
                    })

                    if(updated == false){
                        existingStatusList.push(companyStatus);
                    }
                    setCompanyStatusList(existingStatusList);
                    // console.log(companyStatusList);
                });
            })
        })
    }, [companyList])

    return (
        <>
            <FormControl sx={{m: 1, minWidth: 120, width: '100%'}} size="small">
                <InputLabel>Select a Company</InputLabel>
                <Select
                    // labelId="demo-controlled-open-select-label"
                    // id="demo-controlled-open-select"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={DashboardStore.getSelectedCompany}
                    label="Select a Company"
                    onChange={handleChange}
                >
                    {companyList!.map((companyItem: CompanyMenuItemProps) =>
                        // <CompanyMenuItem {...companyItem} key={companyItem.id}/>
                        <MenuItem value={companyItem.id} sx={{display: 'flex', flexDirection: 'column'}} key={companyItem.id}>
                            <Container sx={{fontWeight:"bold"}} disableGutters>{companyItem.name} - {companyItem.id}</Container>
                            <Container disableGutters sx={{display:'flex', flexDirection:"row"}}>
                                <Typography>Status:</Typography>
                                {companyStatusList!.map((singleCompanyStatus: CompanyStatusMenuItemProps) => {
                                    if(singleCompanyStatus.id === companyItem.id){
                                        return <Typography sx={{ml:1}}>{singleCompanyStatus.status}</Typography>
                                    }
                                })}

                                <Typography sx={{ml:3}}>Annual Report: </Typography>
                                {companyStatusList!.map((singleCompanyStatus: CompanyStatusMenuItemProps) => {
                                    if(singleCompanyStatus.id === companyItem.id){
                                        return <Typography sx={{ml:1}}>{singleCompanyStatus.annualReport}</Typography>
                                    }
                                })}
                            </Container>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            {DashboardStore.getSelectedCompany === '' ?
                <Container disableGutters sx={{height:300, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <Typography variant={"h3"} sx={{textAlign:'center'}}>Please select a company to get started</Typography>
                </Container>
                : null}


        </>
    );
};


export default observer(CompanySelector)