import React, {useEffect, useState} from 'react'
import './App.css'
import {useNavigate, useSearchParams} from "react-router-dom";
import Loading from "./components/loading";
import AlreadyRenewed from "./components/already-renewed";
import NoFurtherRenewals from "./components/no-further-renewals";
import CompanyNotFound from "./components/company-not-found";
import CompanyRenew from "./components/company-renew";
import {observer} from "mobx-react";
import {determineLocation} from "./assets/determineLocation";
import CompanyDissolved from './components/company-dissolved';

const Renew = () => {

    const [count, setCount] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams();
    const [renewalStatus, setRenewalStatus] = useState(0);
    const [renewalStatePromise, setRenewalStatePromise] = useState(null);

    const [loading, setLoading] = useState(true);

    const documentNumber = searchParams.get("d");
    const navigate = useNavigate();

    React.useEffect(() => {
        if (count > 5) {
            navigate('/already-renewed');
        }
    });

    useEffect(() => {
        // setRenewalStatus(406);
        // setLoading(false);

        if (renewalStatus == 0) {
            setLoading(true);
            fetch(`${determineLocation()}/company/${documentNumber}/status`)
                .then(x => x.json()
                    .then((body) => {
                        console.log(body);
                        if(body.code == 404){
                            //navigate to the company does not exist page.
                            // console.log("does not exist");
                            setRenewalStatus(404);
                        }
                        if(body.code == 202){
                            navigate(`/receipt?receipt=${body.message.id}`)
                        }
                        if(body.code == 500){
                            //Try the backup
                            fetch(`${determineLocation()}/company/${documentNumber}/status2`)
                                .then(x => x.json()
                                    .then((body) => {
                                        console.log(body);
                                        if(body.code == 404){
                                            //navigate to the company does not exist page.
                                            // console.log("does not exist");
                                            setRenewalStatus(404);
                                        }
                                        if(body.code == 202){
                                            navigate(`/receipt?receipt=${body.message.id}`)
                                        }
                                        setLoading(false);
                                        setRenewalStatus(body.code);
                                    })
                                );
                        }else{
                            setLoading(false);
                            setRenewalStatus(body.code);
                        }

                    })
                );
        }
    }, []);

    if (loading) {
        return <Loading/>
    }

    return (<NoFurtherRenewals/>)

    switch(renewalStatus){
        case 200:
            return (<AlreadyRenewed />)
        case 404:
            console.log("company not found");
            return (<CompanyNotFound />)
        case 405:
            console.log("company dissolved");
            return (<CompanyDissolved />)
        case 406:
            return (<CompanyRenew documentNumber={documentNumber}/>)
        case 407:
            console.log("formed this year");
        //Also need to do the Voucher page here.
    }

    return (
        <div className="Renew">
            <h1>Renew page</h1>
            <div className="card">
                {renewalStatus}
            </div>
        </div>
    )
}

export default observer(Renew);
