import React, {useEffect, useState} from 'react'
import {Avatar, Container, Divider, Link, Typography} from "@mui/material";
import logoChipGrey from "../../assets/logo-chip-grey.png";
import {background} from "../../assets/colors";

const Footer = () => {

    return (
        <>
            <section>
                <Divider/>
                <Container maxWidth={false} sx={{py: 0, px: {xs: 0, md: 10}, pb:2}}>
                    <Container maxWidth="lg" sx={{
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <Avatar src={logoChipGrey} sx={{
                            width: {xs: 50, md: 50},
                            height: {xs: 50, md: 50},
                            p: 2,
                        }}/>
                        <Container sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Link href={"https://www.fcfiling.com/terms-and-conditions"}>Terms and Conditions</Link>
                            <Container disableGutters sx={{mx:2, width:'fit-content'}}>|</Container>
                            <Link href={"https://www.fcfiling.com/privacy"}>Privacy Policy</Link>
                        </Container>
                        <Typography>Fortress Compliance LLC does not provide financial and/or legal advice; does not review information you provide to us for legal accuracy or sufficiency; is not affiliated with, approved or endorsed by any government agency.</Typography>
                    </Container>
                </Container>

            </section>

        </>
    )
}

export default Footer;
