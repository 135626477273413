import React, {ChangeEvent, useState} from 'react'
import './App.css'
import {observer} from "mobx-react";
import Home from "./components/home/Home";
import About from "./components/home/About";
import Services from "./components/home/Services";
import Faq from "./components/home/Faq";
import Contact from "./components/home/Contact";
import Newsletter from "./components/home/Newsletter";
import Footer from "./components/home/Footer";
import ResponsiveAppBarCombined from "./components/responsive-app-bar-combined";
import BusinessFormation from "./components/home/BusinessFormation";
import OnePortalToManage from "./components/home/OnePortalToManage";
import Spacer from "./components/Spacer";
import {CircularProgress, Container, TextField, Typography} from "@mui/material";
import ErrorMessage from "./components/error";
import {DarkButton, LightButton} from "./components/buttons";
import {changePassword, createAccount} from "./api/client";
import {useNavigate, useSearchParams} from "react-router-dom";

const PasswordReset = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    const handlePasswordChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setPassword((event.target as HTMLInputElement).value);
        if(errorMessage != ""){
            setErrorMessage("");
        }
    };

    const handleConfirmPasswordChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword((event.target as HTMLInputElement).value);
        if(errorMessage != ""){
            setErrorMessage("");
        }
    };

    const handlePasswordReset = () => {
        if(password == ""){
            setErrorMessage("Please enter a password");
            return;
        }
        if(password !== confirmPassword){
            setErrorMessage("Passwords must be the same");
            return;
        }
        setLoading(true);
        changePassword(searchParams.get("token")!, password).then(([status, response])=>{
            if(status === 201){
                navigate('/dashboard');
            }else{
                setErrorMessage(response);
            }
        });

    }

    return (
        <>
            {/*This does not update automatically when it changes, we should change it..and set this into the store to ensure we're updating all things.*/}
            <ResponsiveAppBarCombined showLogin={true}/>
            <main>
                <Container disableGutters sx={{display:'flex', alignItems:'center', justifyContent:'start', flexDirection:'column', height:'70vh'}}>
                    <Spacer height={50}/>
                    <Typography variant={"h4"}>Password Reset</Typography>
                    <Container disableGutters maxWidth={"xs"} sx={{display: 'flex', flexDirection: 'column', mx: 0}}>
                        <TextField label="Enter Password" variant="outlined" type={'password'} sx={{my: 2, mb: 1}} value={password} onChange={handlePasswordChange}/>
                        <TextField label="Confirm Password" variant="outlined" type={'password'} value={confirmPassword} onChange={handleConfirmPasswordChange}/>

                        <Spacer height={20}/>
                        {/*<LightButton variant={'contained'} sx={{p: 2, mt: 3, width: '100%'}}>Create Account</LightButton>*/}
                        <ErrorMessage message={errorMessage}/>
                        <DarkButton disabled={loading} onClick={handlePasswordReset} variant={'contained'}
                                    sx={{p: 2, mt: 3, width: '100%'}}>{(loading ?
                            <CircularProgress size={20}/> : "Change Password")}</DarkButton>
                    </Container>
                </Container>

                <Footer />

            </main>

        </>
    )
}

export default PasswordReset;
