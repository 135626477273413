import {action, makeObservable, observable} from "mobx";

class DashboardStore {
    loading = true;
    loadingArray = {};
    selectedCompany: any = '';

    constructor() {
        makeObservable(this, {
            loading: observable,
            selectedCompany: observable,
            // loadingArray: observable,
            setLoading: action.bound,
            setSelectedCompany: action.bound,
            // setLoadingValue: action.bound,
            // removeItem: action.bound,
            // count: computed,
        });
    }

    setLoadingValue = (loading: boolean) => {
        this.loading = loading;
    }

    setLoading = (name: string, loading: boolean) => {
        this.loadingArray[name] = loading;
        this.calculateLoading();
    }

    calculateLoading() {
        let oldStatus = this.loading;
        let newStatus: undefined | boolean;
        // console.log("calculate loading");
        // console.log(this.loadingArray);
        Object.keys(this.loadingArray).forEach((key)=>{
            if(this.loadingArray[key] === true){
                newStatus = true;
            }
        })

        if (newStatus === undefined) {
            // console.log("new status is undefined")
            //loading should be false
            this.setLoadingValue(false);
        } else if (oldStatus !== newStatus) {
            //This flips from false to true
            // console.log("not undefined anymore");
            this.setLoadingValue(true);
        }
    }

    get getLoading() {
        return this.loading;
    }

    setSelectedCompany = (company: any) => {
        this.selectedCompany = company;
    }

    get getSelectedCompany(){
        return this.selectedCompany;
    }
}

export default new DashboardStore();