import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {background, black} from "../assets/colors";
import logoWide from '../assets/logo-wide.png';
import logoChip from '../assets/logo-chip.png';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {observer} from "mobx-react";
import Store from "../store";
import ResponsiveAppBarLoggedIn from "./responsive-app-bar-logged-in";
import ResponsiveAppBar from "./responsive-app-bar";


const ResponsiveAppBarCombined = observer(({showLogin}:{showLogin:boolean}) => {
    return (
        Store.isLoggedIn ? <ResponsiveAppBarLoggedIn/> : <ResponsiveAppBar showLogin={true}/>
    );
})
export default ResponsiveAppBarCombined;