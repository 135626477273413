import React, {useEffect, useState} from 'react'
import {Avatar, Container, Divider} from "@mui/material";
import {background, main} from "../../assets/colors";
import logoChip from "../../assets/logo-chip.png";
import folderIcon from "../../assets/folder.png";
import Typography from "@mui/material/Typography";
import Spacer from "../Spacer";

const About = () => {

    return (
        <>
            <section>
                <div className="anchor" id="About"/>
                <Container maxWidth={false} sx={{background: background, py: 20, px: {xs: 0, md: 10}}}>
                    <Container maxWidth="lg" sx={{
                        display: "flex",
                        flexDirection: {xs: 'column', md: 'row'},
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Avatar src={logoChip} sx={{
                            width: {xs: 100, md: 125},
                            height: {xs: 100, md: 125},
                            background: "white",
                            p: 2,
                            boxShadow: 2
                        }}/>
                        <Typography sx={{
                            textAlign: "left",
                            p: 3,
                            fontSize: {xs: '1.2rem', md: '1.5rem'},
                            fontWeight: 'medium'
                        }}>Fortress Compliance is a business software company offering digital document management
                            services and directed corporate compliance filings. We are dedicated to meeting our clients
                            needs through speedy service, transparent pricing, and excellent client communication.</Typography>
                    </Container>
                </Container>
            </section>

        </>
    )
}

export default About;
