import React from "react";
import ResponsiveAppBar from "./responsive-app-bar";
import {Container, Typography} from "@mui/material";
import Spacer from "./Spacer";
import Footer from "./home/Footer";

function NoFurtherRenewals(){
    return (<>
        <ResponsiveAppBar showLogin={false}/>
        <Container maxWidth={"md"}>
            <Spacer height={50}/>
            <Typography align={"center"} variant={"h4"}>Our 2024 Directed Annual Report Service Period Has Ended</Typography>
            <Spacer height={50}/>
            <Typography align={"center"}>
                Thank you for choosing Fortress Compliance. The period for our Directed Annual Report Service for 2024 has ended. For more information on filing your annual report at this time please visit the Florida Department of State’s Division of Corporations.
            </Typography>
            <Spacer height={50}/>
            <Typography align={"center"}>
                Feel free to contact our support team at <a href={"mailto:support@fcfiling.com"}>support@fcfiling.com</a> with any further questions.
            </Typography>
        </Container>
        <Spacer height={250}/>
        <Footer/>

    </>);
}

export default NoFurtherRenewals