import {Button, Card, Container, Link} from "@mui/material";
import {grey, main} from "../../assets/colors";
import Typography from "@mui/material/Typography";
import React from "react";

export interface DocumentBoxProps {
    title: string,
    company_name: string,
    company_id: string,
    date: string,
    link?: string,
    canceled: boolean,
    type: string,
}

export const DocumentBox = (props: DocumentBoxProps) => {
    function clickAction(){
        console.log(props.link);
        if(props.link){
            window.open(props.link, '_blank');
        }
    }

    return (
        <a href={props.link} target={"_blank"}>
            <Card sx={{position:'relative', width: 250, height: 250, zIndex: 1, boxShadow: 5, cursor:"pointer"}}>
                {props.canceled ?<Container disableGutters sx={{textAlign:"center", position:'absolute', height:25, bottom:20, right:-90, transform:'rotate(-45deg)', background:'red'}}>
                    Canceled
                </Container> : null }


                <Container className={"cutout"} sx={{
                    background: props.type == 'annual_report' ? main : grey,
                    height: "30%",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: props.canceled ? .5 : 1,
                }}>
                    <Typography className={"cutout-text"} sx={{
                        textAlign: "center",
                        fontSize: "1.5rem",
                        color: 'white',
                        fontWeight: '900',
                        lineHeight:1,
                        pt:2,
                    }}>{props.title}</Typography>
                </Container>
                <Container sx={{height:'175px', width:'100%', display:'flex', flexDirection:'column', alignItems:'start', justifyContent:'end'}}>
                    <Typography sx={{
                        textAlign: "center",
                        fontSize: '1.2rem',
                        pb:0,
                        pt:1,
                    }}>{props.type === 'corporate_renewal' ? "Annual Report Receipt" : null}</Typography>
                    <Typography sx={{
                        textAlign: "center",
                        fontSize: '1.2rem',
                        pb:0,
                        pt:1,
                    }}>{props.company_name}</Typography>
                    <Typography sx={{
                        textAlign: "center",
                        fontSize: '1.2rem',
                        pb:1,
                        pt:0,
                    }}>{props.company_id}</Typography>
                    <Typography sx={{
                        textAlign: "center",
                        fontSize: '1.2rem',
                        pb:1,
                        pt:2,
                    }}>{props.date}</Typography>
                </Container>
            </Card>
        </a>


    );
}