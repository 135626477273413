export class Officer {
    public id: number;
    public title: string | null;
    public type: string | null;
    public firstName: string | null;
    public lastName: string | null;
    public middleName: string | null;
    public titleName: string | null;
    public address: string | null;
    public city: string | null;
    public state: string | null;
    public zipCode: string | null;

    constructor(props: { id: number; title: string | null; type: string | null; firstName: string | null; lastName: string | null; middleName: string | null; titleName: string | null; address: string | null; city: string | null; state: string | null; zipCode: string | null; }) {
        this.id = props.id;
        this.title = props.title;
        this.type = props.type;
        this.firstName = props.firstName;
        this.lastName = props.lastName;
        this.middleName = props.middleName;
        this.titleName = props.titleName;
        this.address = props.address;
        this.city = props.city;
        this.state = props.state;
        this.zipCode = props.zipCode;
    }
}