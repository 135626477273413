import React, {useEffect, useState} from 'react'
import background from "../../assets/home-background.jpg";
import {Button, Card, Container, Dialog, DialogTitle} from "@mui/material";
import {SimpleDialog} from "../simple-dialog";
import {AccountLogin} from "../account-login";
import ResponsiveAppBarLoggedIn from "../responsive-app-bar-logged-in";
import ResponsiveAppBar from "../responsive-app-bar";
import store from "../../store";
import {observer} from "mobx-react";

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
}

const Home = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <section>
                <div className="anchor" id="Home"/>
                <Container maxWidth={false} disableGutters
                           sx={{
                               minHeight: {xs: 300, md: 500},
                               height: {xs: "50vh", md: "70vh"},
                               backgroundImage: `url(${background})`,
                               backgroundSize: 'cover',
                               backgroundPosition: 'center',
                               display:'flex',
                           }}>
                    <Container disableGutters maxWidth={false} sx={{display: 'flex', flexDirection: 'column', padding: 2}}>
                        <Container disableGutters maxWidth={false} sx={{flexGrow: 1}}>

                        </Container>
                        <Container disableGutters maxWidth={false} sx={{display: 'flex', flexGrow: 0}}>
                            <Container sx={{
                                color: "white",
                                flexGrow: 1,
                                display: {xs: 'flex', md: 'flex'},
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Container
                                    disableGutters sx={{
                                        lineHeight: 1,
                                        fontSize: {xs: '1.7rem', md: '2.5rem'},
                                    fontWeight:'900',
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                    Helping
                                    small business owners focus on what's most important - growing their
                                    business.
                                </Container>
                            </Container>
                            {!store.isLoggedIn ?
                                <Container disableGutters sx={{
                                    width:'unset',
                                    color: "white",
                                    display: {xs: 'none', md: 'flex'},
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Card sx={{height: 300, width: 250, p:1}}>
                                        {store.isLoggedIn ? <div>Logged In Already</div> : <AccountLogin email={null}/>}
                                    </Card>

                                </Container>
                            :
                                <Container disableGutters sx={{height: 300, width: 250, p:1}}></Container>}

                        </Container>
                    </Container>


                </Container>
            </section>

        </>
    )
}

export default observer(Home);
