import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Loading from "./components/loading";
import {determineLocation} from "./assets/determineLocation";
import {Container} from "@mui/material";
import Receipt from "./Receipt";
import ResponsiveAppBar from "./components/responsive-app-bar";
import Spacer from "./components/Spacer";
import {RenewalStepFlowDisplay} from "./components/renewal-step-flow-display";
import Footer from "./components/home/Footer";
import Store from "./store";
import {SignatureDialogProps} from "./components/signature-dialog";
import { observer } from "mobx-react";
import ResponsiveAppBarCombined from "./components/responsive-app-bar-combined";

const ReceiptWrapper = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const receiptNumber = searchParams.get("receipt");
    const navigate = useNavigate();

    const [confirmed, setConfirmed] = useState(true);

    React.useEffect(() => {
        // fetch(`${determineLocation()}/billing/payment/complete/${paymentSecret}`, {method: 'POST'})
        //     .then(x => x.json().then((json)=>{
        //         if(x.status == 200){
        //             // navigate(`/receipt?receipt_id=${json.message}`)
        //
        //             window.history.replaceState(null, "Receipt", `/receipt?receipt=${json.message}`);
        //             setConfirmed(true)
        //             Store.setRenewStep(3)
        //
        //         }else{
        //             console.log("failed to get receipt");
        //         }
        //     }))
    });

    if (!confirmed) {
        return <Loading/>
    }

    return (<>
        <ResponsiveAppBarCombined showLogin={false}/>
        <Container maxWidth={"md"}>
            <Spacer height={50}/>
            <Receipt/>
        </Container>
        <Spacer height={250}/>
        <Footer/>

    </>);

}

export default ReceiptWrapper