import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Loading from "./components/loading";
import {determineLocation} from "./assets/determineLocation";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop, Button, Chip,
    CircularProgress,
    Container, Grid,
    Typography
} from "@mui/material";
import Receipt from "./Receipt";
import ResponsiveAppBar from "./components/responsive-app-bar";
import Spacer from "./components/Spacer";
import {RenewalStepFlowDisplay} from "./components/renewal-step-flow-display";
import Footer from "./components/home/Footer";
import Store from "./store";
import ResponsiveAppBarLoggedIn from "./components/responsive-app-bar-logged-in";
import {grey} from "./assets/colors";
import CompanySelector from "./ui/company-selector";
import DashboardStore from "./stores/dashboard-store";
import {observer} from "mobx-react";
import {DashboardHeader} from "./ui/dashboard/dashboard-header";
import {DocumentBoxSwiper} from "./ui/dashboard/document-box-swiper";
import WarningIcon from '@mui/icons-material/Warning';
import {getFetch, postFetch} from "./api/client";
import {InfoPopover} from "./ui/info-popover";
import { InlineButton } from "./components/buttons";


const Dashboard = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const paymentSecret = searchParams.get("payment_intent_client_secret");
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [recentDocumentsLoading, setRecentDocumentsLoading] = useState(true);
    const [recentDocuments, setRecentDocuments] = useState([]);

    const [annualReportsDocumentsLoading, setAnnualReportsDocumentsLoading] = useState(true);
    const [annualReportsDocuments, setAnnualReportsDocuments] = useState([]);

    const [receiptsDocumentsLoading, setReceiptsDocumentsLoading] = useState(true);
    const [receiptsDocuments, setReceiptsDocuments] = useState([]);

    const [annualReportStatus, setAnnualReportStatus] = useState("");
    const [annualReportStatusResponse, setAnnualReportStatusResponse] = useState(0);
    const [currentAnnualReportReceipt, setCurrentAnnualReportReceipt] = useState('');

    const [isActivated, setIsActivated] = useState(true);
    const [resendMessage, setResendMessage] = useState("");

    useEffect(() => {
        const checkLoggedIn = async () => {
            await Store.getLoggedIn();
            if (!Store.isLoggedIn) {
                navigate("/");
            }
            setLoading(false);
        }

        checkLoggedIn().catch(console.error);
    }, [])

    useEffect(() => {
        //Check that we are not like..not logged in.
        getFetch({
            endpoint: `api/auth/check`,
            authenticate: true
        }).then((r) => {
            if (r.status != 204) {
                setIsActivated(false);
            }
        })
    })

    useEffect(() => {
        if (DashboardStore.getSelectedCompany !== '') {
            setRecentDocumentsLoading(true);
            setAnnualReportsDocumentsLoading(true);
            setReceiptsDocumentsLoading(true);

            getFetch({
                endpoint: `document/recent/${DashboardStore.getSelectedCompany}`,
                authenticate: true
            }).then((r) => {
                if (r.status === 200) {
                    r.json().then((body) => {
                        setRecentDocuments(body);
                        setRecentDocumentsLoading(false);
                    })
                }
            })

            getFetch({
                endpoint: `document/annualReports/${DashboardStore.getSelectedCompany}`,
                authenticate: true
            }).then((r) => {
                if (r.status === 200) {
                    r.json().then((body) => {
                        setAnnualReportsDocuments(body);
                        setAnnualReportsDocumentsLoading(false);
                    })
                }
            })

            getFetch({
                endpoint: `document/receipts/${DashboardStore.getSelectedCompany}`,
                authenticate: true
            }).then((r) => {
                if (r.status === 200) {
                    r.json().then((body) => {
                        setReceiptsDocuments(body);
                        setReceiptsDocumentsLoading(false);
                    })
                }
            })

            getFetch({
                endpoint: `company/${DashboardStore.getSelectedCompany}/status`,
            }).then((r) => {
                r.json().then((body) => {
                    setAnnualReportStatusResponse(r.status);
                    switch (r.status) {
                        case 202:
                            setAnnualReportStatus(body.message.status);
                            setCurrentAnnualReportReceipt(body.message.id);
                            break;
                        default:
                            setAnnualReportStatus("Not Filed");
                            break;
                    }
                });
            })

        }

    }, [DashboardStore.getSelectedCompany])

    function figureOutRenewButton() {
        switch (annualReportStatusResponse) {
            case 406:
            case 200:
                return (
                    <Container disableGutters sx={{display: 'flex', justifyContent: 'start'}}>
                        <Button onClick={() => {
                            navigate(`/renew/?d=${DashboardStore.getSelectedCompany}`);
                        }} disabled={annualReportStatusResponse === 200} variant={'contained'}>Renew Now</Button>
                    </Container>
                )
            case 202:
                return (
                    <Container disableGutters sx={{display: 'flex', justifyContent: 'start'}}>
                        <Button onClick={() => {
                            navigate(`/receipt?receipt=${currentAnnualReportReceipt}`);
                        }} variant={'contained'}>View Receipt</Button>
                    </Container>
                )
            default:
                return (<></>)
        }

    }

    return (<>
        <ResponsiveAppBarLoggedIn/>
        {!isActivated ?
            pleaseActivate()
            :
            <Container maxWidth={"md"} sx={{my: 2, borderRadius: 1}}>
                <CompanySelector/>
                {DashboardStore.getSelectedCompany != '' ?
                    <>
                        <Grid sx={{m: 1}} container spacing={2}>
                            <Grid xs={12} sm={5} sx={{mt: 1, mb: 1}}>
                                <Container disableGutters sx={{display: 'flex', justifyContent: 'start', mb: 1}}>
                                    <Typography variant={"h5"}>Status</Typography>
                                    <InfoPopover
                                        text={"This information is obtained directly from the Florida Division of Corporations. This information may be subject to delays."}/>
                                    <Chip sx={{ml: 1, fontSize:"1rem"}} label="Active" variant="outlined"/>
                                </Container>
                                <Container disableGutters sx={{display: 'flex', justifyContent: 'start'}}>
                                    <Button disabled variant={'contained'}>Reinstate</Button>
                                </Container>
                            </Grid>
                            <Grid xs={12} sm={5} sx={{mt: 1, mb: 1}}>
                                <Container disableGutters sx={{display: 'flex', flexDirection: 'column'}}>
                                    <Container disableGutters sx={{display: 'flex', justifyContent: 'start', mb: 1}}>
                                        <Typography variant={"h5"}>Annual Report</Typography>
                                        <InfoPopover
                                            text={"This information is obtained directly from the Florida Division of Corporations. This information may be subject to delays."}/>
                                        {annualReportStatus === "" ? <CircularProgress size={20}/> :
                                            <Chip sx={{ml: 1, fontSize:"1rem"}} label={
                                                <Container disableGutters sx={{display:'flex'}}>
                                                    <Typography>{annualReportStatus}</Typography>
                                                    {annualReportStatus === "Not Filed" ? <WarningIcon sx={{ml:1}} color={"error"}/> : null}
                                                </Container>
                                            } variant="outlined"/>}
                                    </Container>
                                    {figureOutRenewButton()}

                                </Container>

                            </Grid>
                        </Grid>

                        <DocumentBoxSwiper hideIfEmpty={true} loading={recentDocumentsLoading} collapsible={false}
                                           title={"Recent Documents"} documentBoxes={recentDocuments}/>

                        <DocumentBoxSwiper title={"Annual Reports"} loading={annualReportsDocumentsLoading}
                                           documentBoxes={annualReportsDocuments}
                                           emptyText={"No annual reports found"}/>

                        <DocumentBoxSwiper title={"Receipts"} loading={receiptsDocumentsLoading}
                                           documentBoxes={receiptsDocuments}
                                           emptyText={"No reports found"}/>

                        <DocumentBoxSwiper defaultExpanded={false} title={"Articles of Incorporation"}
                                           emptyText={"You did not file this companies Articles of Incorporation with us. These can be found at the Department of Corporation services."}
                                           documentBoxes={[]}/>
                        <DocumentBoxSwiper defaultExpanded={false} title={"EIN"}
                                           emptyText={"You did not request your EIN through us."} documentBoxes={[]}/>

                    </>
                    : null}


            </Container>
        }


        <Spacer height={250}/>
        <Footer/>

        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={DashboardStore.getLoading}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>

    </>);

    function pleaseActivate() {
        return <Container>
            <Spacer height={25}/>
            <Typography>An activation email link was sent to your email. Please follow the instructions in the email to activate your account</Typography>
            <Spacer height={25}/>
            <Typography>If you did not receive your activation email, please check your spam folder or <InlineButton sx={{marginLeft:0}}
                onClick={resendActivationEmail} disabled={resendMessage == "Processing Request..."}>click here to resend it.</InlineButton></Typography>
            <Spacer height={20}/>
            <Typography sx={{color:"red", fontWeight:"bold"}}>{resendMessage}</Typography>
        </Container>
    }

    function resendActivationEmail() {
        setResendMessage("Processing Request...");
        postFetch({endpoint: `api/auth/resendActivation`, body:{}, authenticate:true}).then((r) => {
            // if (r.status != 200) {
                r.json().then(body=>{
                    console.log(body);
                    setResendMessage(body);
                });
            // }
        })
    }

}

export default observer(Dashboard)