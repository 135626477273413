import React, {useEffect, useState} from 'react'
import {Avatar, Container, Divider} from "@mui/material";
import {background, main} from "../../assets/colors";
import logoChip from "../../assets/logo-chip.png";
import buildingIcon from "../../assets/icons-business-building.png";
import Typography from "@mui/material/Typography";
import Spacer from "../Spacer";

const BusinessFormation = () => {

    return (
        <>
            <section>
                <div className="anchor" id="BusinessFormation"/>
                <Container maxWidth={false} sx={{
                    pb:10,
                    px: {xs: 0, sm: 3, md: 3},
                    position: 'relative',
                    background: "linear-gradient(135deg, #009fdf, #006baf);",
                }} disableGutters>
                    <Container sx={{width: 200, height: 200, pt: 10}}>
                        <img src={buildingIcon} style={{width: '100%'}}/>
                    </Container>
                    <Typography sx={{
                        color: "white",
                        textAlign: "center",
                        p: 3,
                        mt:2,
                        fontSize: {xs: '1.8rem', md: '2.3rem'},
                        lineHeight: {xs: 2, md: 1},
                        fontWeight: 'medium'
                    }}>Start your business today.</Typography>
                    <Spacer height={50}/>
                    <Typography sx={{
                        color: "white",
                        textAlign: "left",
                        p: 3,
                        fontSize: {xs: '1.5rem', md: '2rem'},
                        lineHeight: {xs: 1.2, md: 1},
                        fontWeight: '900'
                    }}>Business Formation</Typography>
                    <Typography sx={{
                        color: "white",
                        textAlign: "left",
                        p: 3,
                        fontSize: {xs: '1.2rem', md: '1.5rem'},
                        lineHeight: 1.3,
                        fontWeight: 'normal'
                    }}>Starting a new business? Expanding into a new state? Our business formation services allow you
                        to easily incorporate your new business using our secure portal. Simply provide us with the
                        requisite information and we will file your formation documents with the appropriate state
                        agency. Through your business profile page you can quickly and easily access your formation
                        documents, manage multiple entities, and utilize our Directed Annual Report Service to stay on
                        top of your annual compliance filings.</Typography>
                </Container>
            </section>

        </>
    )
}

export default BusinessFormation;
