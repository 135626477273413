import {observer} from "mobx-react";
import React from "react";
import {Container} from "@mui/material";

const ErrorMessage = observer(({message}: {message: string}) => {
    return (
        <>
            <Container sx={{display:'flex', justifyContent:'center'}}>
                <div style={{color:"red", fontWeight:"bold"}}>{message}</div>
            </Container>
        </>
    )
})

export default ErrorMessage