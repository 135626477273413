import React from "react";
import {CircularProgress, Container} from "@mui/material";

function Loading() {
    return (<Container maxWidth={false} sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
    }}><CircularProgress/></Container>);
}

export default Loading