import {Container, Typography} from "@mui/material";
import Store from "../store";
import React from "react";

export const RenewalStepFlowDisplay = () => {
    return (<Container sx={{display:'flex', justifyContent:'center'}}>
    <Container maxWidth={false} className={`step ${(Store.getRenewStep == 1 ? 'activeStep' : '')}`}>
    <Typography sx={{textAlign:'center', fontSize:{xs: '.8rem', md: '1rem'}}}>01 Confirm Your Details</Typography>
    </Container>
    <Container className={`step ${(Store.getRenewStep == 2 ? 'activeStep' : '')}`}>
    <Typography sx={{textAlign:'center', fontSize:{xs: '.8rem', md: '1rem'}}}>02 Enter Payment Details</Typography>
    </Container>
    <Container className={`step ${(Store.getRenewStep == 3 ? 'activeStep' : '')}`}>
    <Typography sx={{textAlign:'center', fontSize:{xs: '.8rem', md: '1rem'}}}>03 Confirmation</Typography>
    </Container>
    </Container>)

}
