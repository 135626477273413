import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, Container, Divider, Icon, Link, TextField, Typography} from "@mui/material";
import Spacer from "./components/Spacer";
import {LightButton} from "./components/buttons";
import {SimpleDialog} from "./components/simple-dialog";
import {CancelDialog} from "./components/cancel-dialog";
import {determineLocation} from "./assets/determineLocation";
import Store from "./store";
import Loading from "./components/loading";
import {format} from 'date-fns';
import {AccountCreate} from "./components/account-create";
import {AccountLogin} from "./components/account-login";
import {main} from "./assets/colors";
import folderIcon from "./assets/folder.png";
import { observer } from "mobx-react";
import Swal from "sweetalert2";

export interface ReceiptBillingDetailOutput {
    last4: string | null,
    name: string | null,
    address1: string | null,
    address2: string | null,
    city: string | null,
    state: string | null,
    zip: string | null,
}

export interface ReceiptOutput {
    email: string | null,
    receiptNumber: string | null,
    status: string | null,
    paymentDate: string | null,
    cancelDate: string | null,
    hasAccount: boolean | null,
    companyName: string | null,
    documentNumber: string | null,
    price: string | null,
    billingDetails: ReceiptBillingDetailOutput | null,
    serviceYear: string | null,
}

class OrderStatusMessages {
    public statusMessage: string
    public infoMessage: string

    public constructor(statusMessage: string, infoMessage: string) {
        this.statusMessage = statusMessage;
        this.infoMessage = infoMessage;
    }


}

const Receipt = observer(() => {
    const [searchParams, setSearchParams] = useSearchParams();
    const receiptNumber = searchParams.get("receipt");
    const cancel = searchParams.get("cancel");
    const [receipt, setReceipt] = useState<ReceiptOutput | null>(null);

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(true);

    const [showLogin, setShowLogin] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        if (receipt == null) {
            fetch(`${determineLocation()}/receipt/${receiptNumber}`, {method: 'GET'})
                .then(x => x.json().then((json) => {
                    if (x.status == 200) {
                        setReceipt(json.message);
                        if(cancel){
                            setSearchParams({receipt:receiptNumber!})
                            if(json.message.status == 'pending' || json.message.status == 'failed'){
                                setOpen(true);
                            }else{
                                if(json.message.status =='canceled'){
                                    Swal.fire(
                                        "Your order has already been successfully canceled."
                                    )
                                }else{
                                    if(json.message.status =='processing'){
                                        Swal.fire(
                                            {
                                                title:"Cannot Cancel",
                                                text:"Your order can no longer be canceled as it has already started processing. Please contact our customer support with further questions at support@fcfiling.com"
                                            }
                                        )
                                    }else{
                                        Swal.fire(
                                            {
                                                title:"Cannot Cancel",
                                                text:"Your order has already been completed. Please check your email for a copy of your filing. Feel free to contact customer support with further questions at support@fcfiling.com"
                                            }
                                        )
                                    }

                                }

                            }

                        }
                    } else {
                        console.log("failed to get receipt");
                    }
                    setLoading(false)
                }))
        }

    });

    if (loading) {
        return <Loading/>
    }

    function determineOrderStatus(): OrderStatusMessages {
        switch (receipt!.status) {
            case "pending":
            case "processing":
            case "failed":
                // return new OrderStatusMessages("Your order is being processed.", "Please allow 1-3 business days for your filing to be completed. Upon completion, you will receive an email confirmation and copy of your filing.");
                return new OrderStatusMessages("Your order is being processed.", "Please allow 24 hours for your filing to be completed. Upon completion, you will receive an email confirmation and copy of your filing.");
            case "finalizing":
                return new OrderStatusMessages("Your order is being finalized.", "");
            case "complete":
                return new OrderStatusMessages("Your order is complete.", `Your corporation has been renewed for ${receipt!.serviceYear}!`);
            case "canceled":
                return new OrderStatusMessages("Your order has been cancelled.", "Log-in to your profile below to view your completed filings.");
            default:
                return new OrderStatusMessages("Your order is in an unknown status.", "");
        }
    }

    return (
        <Container disableGutters sx={{my: 2}}>
            <Typography variant={"h4"} sx={{fontWeight: 300}}>{determineOrderStatus().statusMessage}</Typography>
            <Spacer height={20}/>

            {receipt!.status == 'finalizing' ? <>
                <br/>
                <Typography>
                    <Typography sx={{fontWeight:"bold", display:'inline-block'}}>Your filing has been processed by our team and submitted to the state</Typography>, however the Division of Corporations has been experiencing delays in posting filings. As a result your report has been queued for reconciliation. Our team will continue to follow up with the state on your behalf and notify you when the issue is resolved.
                </Typography>
                <br/>
                <Typography>
                    <Typography sx={{fontWeight:"bold", display:'inline-block'}}>There is no action required from you at this time.</Typography> Once the Division reconciles and posts your report, we will email you a confirmation and copy of your report.
                </Typography>
                <br/>
            </> : null}

            <Typography sx={{fontWeight: 500}}>{receipt!.companyName} - {receipt!.documentNumber}</Typography>
            <Spacer height={20}/>

            <Typography sx={{fontWeight: 500}}>An email confirmation will be sent to:</Typography>
            <Typography>{receipt!.email}</Typography>
            <Spacer height={20}/>

            {receipt!.cancelDate != null ? <><Typography sx={{fontWeight: 500}}>Cancellation Date</Typography>
                <Typography>{receipt!.cancelDate}</Typography>
                <Spacer height={20}/></> : null}

            <Typography sx={{fontWeight: 500}}>Receipt Number</Typography>
            <Typography>{receipt!.receiptNumber}</Typography>
            <Spacer height={20}/>

            <Typography sx={{fontWeight: 500}}>Payment Date</Typography>
            <Typography>{receipt!.paymentDate}</Typography>
            <Spacer height={20}/>

            {receipt!.cancelDate == null ?
                <>
                    <Typography sx={{fontWeight: 500}}>Your credit card will have a charge of {receipt!.price} from
                        FCFILING.COM</Typography>
                    <Spacer height={20}/>
                </> : null}

            <Typography sx={{fontWeight: 500}}>{determineOrderStatus().infoMessage}</Typography>
            <Spacer height={20}/>
            <Typography>If you have any questions please contact us at <Link
                href={"mailto:support@fcfiling.com"}>support@fcfiling.com</Link></Typography>
            <Spacer height={20}/>
            {receipt!.status == 'pending' || receipt!.status == 'failed' ?
                <Typography sx={{fontWeight: 500}}>To cancel your order <Link style={{cursor: "pointer"}}
                                                                              onClick={handleClickOpen}>click
                    here</Link></Typography> : null}

            <Spacer height={10}/>
            <Typography>To view our refund policy visit our <Link href="/#FAQ" style={{cursor:'pointer'}}>Frequently Asked Questions</Link></Typography>

            {receipt!.cancelDate == null ?
                <>
                    <OrderSummary receipt={receipt}/>
                </> : null}


            <Divider sx={{my: 3}}/>

            {!Store.isLoggedIn ? <ReceiptLoginSection hasAccount={receipt!.hasAccount ?? false} email={receipt!.email ?? ""}/> : null}


            <CancelDialog
                open={open}
                onClose={handleClose}
                receiptNumber={receiptNumber}
                receiptStatus={receipt!.status}
            />
        </Container>
    )
})

const ReceiptLoginSection = ({hasAccount, email} : {hasAccount:boolean, email:string}) => {
    return (
        hasAccount == true ? <AccountLogin email={email}/> :
            <AccountCreate email={email}/>
    )
}


const OrderSummary = (receipt: any) => {
    receipt = receipt.receipt;
    return (
        <>
            <Container maxWidth={false} sx={{background: main, my: 2, py: 1}}>
                <Typography variant={"h4"} color={"white"}>Order Summary</Typography>
            </Container>
            <Container sx={{display: 'flex', flexDirection: 'row'}}>
                <img src={folderIcon} style={{width: 50, height: 43}}/>
                <Container>
                    <Typography>Annual Report Filing</Typography>
                    <Typography sx={{fontWeight: 500}}>{receipt!.companyName} - {receipt!.documentNumber}</Typography>
                </Container>
            </Container>
            <Divider sx={{my: 2}}/>
            <Container sx={{display: 'flex', flexDirection: {xs: "column", sm: "row"}}}>
                <Container disableGutters sx={{display: 'flex', flexDirection: {xs: "row", sm: "column"}}}>
                    <Container disableGutters>
                        <Typography>{receipt!.billingDetails.name}</Typography>
                        <Typography>{receipt!.billingDetails.address1}</Typography>
                        {receipt!.billingDetails.address2 != null ?
                            <Typography>{receipt!.billingDetails.address1}</Typography> : null}
                        <Typography>{receipt!.billingDetails.city}, {receipt!.billingDetails.state} {receipt!.billingDetails.zip}</Typography>

                    </Container>
                    <Spacer height={10}/>
                    <Container disableGutters
                               sx={{display: 'flex', flexDirection: 'column', alignItems: {xs: 'end', sm: 'start'}}}>
                        <Typography>Payment Method</Typography>
                        <Typography>{receipt!.billingDetails.last4}</Typography>
                    </Container>

                </Container>

                <Spacer height={10}/>

                <Container disableGutters sx={{display: 'flex', flexDirection: 'column'}}>
                    <Container disableGutters
                               sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography>State Filing Fee: </Typography>
                        <Typography>{receipt!.renewalFee}</Typography>
                    </Container>
                    <Container disableGutters
                               sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography>Service Fee: </Typography>
                        <Typography>{receipt!.serviceFee}</Typography>
                    </Container>
                    <Divider sx={{my: 2}}/>
                    <Container disableGutters
                               sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography>TOTAL </Typography>
                        <Typography>{receipt!.price}</Typography>
                    </Container>
                </Container>
            </Container>
        </>
    )
}

export default Receipt