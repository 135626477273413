import React, {useEffect, useState} from 'react'
import {Avatar, Container, Divider} from "@mui/material";
import {background, main} from "../../assets/colors";
import logoChip from "../../assets/logo-chip.png";
import docsIcon from "../../assets/icons-docs.png";
import Typography from "@mui/material/Typography";
import Spacer from "../Spacer";

const OnePortalToManage = () => {

    return (
        <>
            <section>
                <div className="anchor" id="OnePortal"/>
                <Container maxWidth={false} sx={{
                    pb:10,
                    px: {xs: 0, sm: 3, md: 3},
                }} disableGutters>
                    <Container sx={{width: 200, height: 200, pt: 10}}>
                        <img src={docsIcon} style={{width: '100%'}}/>
                    </Container>
                    <Typography sx={{
                        color: main,
                        textAlign: "center",
                        p: 3,
                        mt:2,
                        fontSize: {xs: '1.8rem', md: '2.3rem'},
                        lineHeight: {xs: 2, md: 1},
                        fontWeight: 'medium'
                    }}>One portal to manage all your filings.</Typography>
                    <Spacer height={50}/>
                    <Typography sx={{
                        color: main,
                        textAlign: "left",
                        p: 3,
                        fontSize: {xs: '1.5rem', md: '2rem'},
                        lineHeight: {xs: 1.2, md: 1},
                        fontWeight: '900'
                    }}>Reinstatement & Dissolution</Typography>
                    <Typography sx={{
                        color: main,
                        textAlign: "left",
                        p: 3,
                        fontSize: {xs: '1.2rem', md: '1.5rem'},
                        lineHeight: 1.3,
                        fontWeight: 'normal'
                    }}>Missed an annual report and need to be reinstated? Need to dissolve an entity? We have you
                        covered. From your dashboard, you can quickly and easily update your entity’s status by
                        providing us with the desired action. We will then file your desired action on your behalf.*</Typography>
                    <Typography sx={{
                        color: main,
                        textAlign: "left",
                        p: 3,
                        fontSize: {xs: '1.2rem', md: '1.5rem'},
                        lineHeight: 1.3,
                        fontWeight: 'normal'
                    }}>*For reinstatements, applicable fees and eligibility will be determined in accordance with your
                        local state's regulations.</Typography>
                </Container>
            </section>

        </>
    )
}

export default OnePortalToManage;
