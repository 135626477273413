import React from 'react'
import './App.css'
import {observer} from "mobx-react";
import Home from "./components/home/Home";
import About from "./components/home/About";
import Services from "./components/home/Services";
import Faq from "./components/home/Faq";
import Contact from "./components/home/Contact";
import Newsletter from "./components/home/Newsletter";
import Footer from "./components/home/Footer";
import ResponsiveAppBarCombined from "./components/responsive-app-bar-combined";
import BusinessFormation from "./components/home/BusinessFormation";
import OnePortalToManage from "./components/home/OnePortalToManage";

const Public = () => {

    return (
        <>
            {/*This does not update automatically when it changes, we should change it..and set this into the store to ensure we're updating all things.*/}
            <ResponsiveAppBarCombined showLogin={true}/>
            <main>
                <Home />
                <About />
                <BusinessFormation />
                <Services />
                <OnePortalToManage />
                <Contact />
                <Faq />
                <Newsletter />
                <Footer />

            </main>

        </>
    )
}

export default observer(Public);
