import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {background, black} from "../assets/colors";
import logoWide from '../assets/logo-wide.png';
import logoChip from '../assets/logo-chip.png';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const pages = ['Home', 'About', 'Services', 'FAQ', 'Contact' ];

export interface ResponsiveAppBarProps {
    showLogin: boolean;
}

function ResponsiveAppBar(props: ResponsiveAppBarProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {showLogin} = props;
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if(window.location.hash != ''){
            scrollIntoView(window.location.hash.replace('#', ''));
        }
    });

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = ({location = null}: {location: string|null}) => {
        setAnchorElNav(null);
        if(location != null){
            scrollIntoView(location);
        }
        setAnchorEl(null);

    };

    const scrollIntoView = async (location) => {
        //If we are not at that page, we should go there.
        if (window.location.pathname != '/') {
            await navigate('/#' + location);
            window.history.replaceState(null, "", "/")
        }
        document.getElementById(location)!.scrollIntoView();
    }

    return (
        <AppBar position="sticky">
            <Container maxWidth={false} disableGutters style={{backgroundColor:"white", color:black}}>
                <Toolbar variant={'dense'} disableGutters sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} >
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <a href={"/"}>
                            <img src={logoWide} style={{marginLeft:10, height:30}} alt={"Fortress Compliance"} />
                        </a>
                    </Box>

                </Toolbar>
                <Toolbar variant={'dense'} disableGutters sx={{backgroundColor:{xs: 'white', md:background}}}>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={()=>{handleCloseNavMenu({location: page})}}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page)=>{
                            if(page === "Services"){
                                return (<>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        sx={{color: black, display: 'block', px:7}}
                                    >
                                        Services
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={()=>{handleCloseNavMenu({location: "BusinessFormation"})}}>Business Formation</MenuItem>
                                        <MenuItem onClick={()=>{handleCloseNavMenu({location: "Services"})}}>Directed Annual Report</MenuItem>
                                        <MenuItem onClick={()=>{handleCloseNavMenu({location: "OnePortal"})}}>Manage Your Filings</MenuItem>
                                    </Menu></>)
                            }
                            return (<Button
                            key={page}
                            onClick={()=>{handleCloseNavMenu({location: page})}}
                            sx={{color: black, display: 'block', px:7}}
                            >
                        {page}
                            </Button>)
                        })}
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
                        <img src={logoChip} style={{marginLeft:10, height:30}}  alt={"Fortress Compliance"}/>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        {showLoginButton(showLogin, navigate)}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

function showLoginButton(showLogin, navigate){
    return showLogin ? <Button
        sx={{ my: 2, color: black, display: 'block' }}
    onClick={()=>navigate('/login')}>
        Login
    </Button> : <></>
}
export default ResponsiveAppBar;