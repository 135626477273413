import * as React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {main} from "../assets/colors";
import {Box, Container} from "@mui/material";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    borderBottom: `1px solid ${main}`,
    color: main,
    '&:not(:last-child)': {
        // borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionTypography = styled(Typography)({
   fontWeight:'900',
});

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    marginLeft: 30
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const fontSx = {fontSize: {xs: '1.2rem', md: '1.5rem'}};

    return (
        <Container disableGutters>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <AccordionTypography sx={fontSx}>Can I cancel my order?</AccordionTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={fontSx}>
                        Yes! Orders can be canceled <Box sx={{display:'inline', fontWeight:'bold', textDecoration:'underline'}}>before</Box> filings are completed. Each emailed receipt you receive
                        contains an order cancellation link. You can also cancel orders from the order confirmation
                        page. If you have trouble accessing or finding the link, you can always contact our support
                        team at <a href={"mailto:support@fcfiling.com"}>support@fcfiling.com</a>.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <AccordionTypography sx={fontSx}>How do I know if my documents have been filed?</AccordionTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={fontSx}>
                        Once your filing is complete, you will receive an email confirmation and digital copy of your filing.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <AccordionTypography sx={fontSx}>How do I access my filed documents?</AccordionTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={fontSx}>
                        You can access all of your past and current filings through your business profile page, which
                        are available once you log in to your individual profile.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <AccordionTypography sx={fontSx}>Can I access more than one business entity from my profile page?</AccordionTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={fontSx}>
                        Yes! From your profile page you are able to view all of your linked businesses.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <AccordionTypography sx={fontSx}>How do I add a new/additional business entity to my profile?</AccordionTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={fontSx}>
                        From your profile page you can add new/additional businesses by selecting "add new business"
                        from the business selector dropdown.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
}