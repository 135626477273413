import {Accordion, AccordionDetails, AccordionSummary, Container, Skeleton, Typography} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import {DocumentBox, DocumentBoxProps} from "./document-box";
import {Navigation} from "swiper";
import {DashboardHeader} from "./dashboard-header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface DocumentBoxSwiperProps {
    collapsible?: boolean,
    title: string,
    documentBoxes: DocumentBoxProps[],
    defaultExpanded?: boolean,
    loading?: boolean,
    hideIfEmpty?: boolean,
    emptyText?: string,
}

export const DocumentBoxSwiper = (props: DocumentBoxSwiperProps) => {
    const {
        documentBoxes,
        title,
        collapsible = true,
        defaultExpanded = true,
        loading = false,
        hideIfEmpty = false,
        emptyText
    } = props;

    if (loading) {
        return (
            <Skeleton variant="rectangular" sx={{m: 1}} width={'100%'} height={100}/>
        )
    }

    if (hideIfEmpty && documentBoxes.length === 0) {
        return (
            <></>
        )
    }

    return (
        <Accordion expanded={!collapsible ? true : undefined} defaultExpanded={defaultExpanded} className="documentList"
                   sx={{boxShadow: "unset"}}>
            <AccordionSummary expandIcon={!collapsible ? null : <ExpandMoreIcon sx={{width:50,height:50,}}/>}>
                <DashboardHeader text={title}/>
            </AccordionSummary>
            <AccordionDetails>
                <Container disableGutters sx={{mb: 1}}>
                    {documentBoxes.length === 0 ? <Typography>{emptyText}</Typography> : null}
                    <Swiper
                        height={275}
                        modules={[Navigation]}
                        spaceBetween={25}
                        slidesOffsetBefore={10}
                        slidesPerView={"auto"}
                        navigation={window.innerWidth > 500}
                        onSlideChange={() => {}}
                        onSwiper={(swiper) =>{}}>
                        {documentBoxes.map((documentBox, index) => {
                            return <SwiperSlide key={documentBox.title + "slide" + documentBox.company_id}
                                                virtualIndex={index}>
                                <DocumentBox
                                    key={documentBox.title + "docbox" + documentBox.company_id} {...documentBox}/>
                            </SwiperSlide>
                        })}
                    </Swiper>

                </Container>
            </AccordionDetails>
        </Accordion>
    );
}