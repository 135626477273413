export function determineLocation() {
    switch (window.location.hostname) {
        case 'localhost':
            return 'http://localhost:4001';
        case 'fcfiling.com':
        case 'www.fcfiling.com':
        case 'www.fcfiling.com/':
        case 'https://www.fcfiling.com':
        case 'https://www.fcfiling.com/':
            return 'https://api.fcfiling.com'
        case 'fortress.test.com':
        default:
            return 'https://fortress-api.uribloch.com';
    }
}