import React, {useEffect, useState} from 'react'
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {main} from '../../assets/colors'
import CustomizedAccordions from "../faq-accordion";

const Faq = () => {

    return (
        <>
            <section>
                <div className="anchor" id="FAQ"/>
                <Container maxWidth={false} disableGutters
                           sx={{
                               position: 'relative',
                               py: 5,
                               px: {xs: 0, sm: 5, md: 10}
                           }}>
                    <Typography sx={{
                        textAlign: "center",
                        p: 3,
                        fontSize: {xs: '1.2rem', md: '2.4rem'},
                        fontWeight: 'normal',
                        color: main,
                    }}>Frequently Asked Questions</Typography>

                    <CustomizedAccordions/>
                </Container>
            </section>

        </>
    )
}

export default Faq;
