import React, {ChangeEvent, useState} from 'react'
import './App.css'
import {observer} from "mobx-react";
import Home from "./components/home/Home";
import About from "./components/home/About";
import Services from "./components/home/Services";
import Faq from "./components/home/Faq";
import Contact from "./components/home/Contact";
import Newsletter from "./components/home/Newsletter";
import Footer from "./components/home/Footer";
import ResponsiveAppBarCombined from "./components/responsive-app-bar-combined";
import BusinessFormation from "./components/home/BusinessFormation";
import OnePortalToManage from "./components/home/OnePortalToManage";
import Spacer from "./components/Spacer";
import {CircularProgress, Container, TextField, Typography} from "@mui/material";
import ErrorMessage from "./components/error";
import {DarkButton, LightButton} from "./components/buttons";
import {changePassword, createAccount} from "./api/client";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Login} from "@mui/icons-material";
import {AccountLogin} from "./components/account-login";

const LoginPage = () => {


    return (
        <>
            {/*This does not update automatically when it changes, we should change it..and set this into the store to ensure we're updating all things.*/}
            <ResponsiveAppBarCombined showLogin={true}/>
            <main>
                <Container disableGutters sx={{display:'flex', alignItems:'center', justifyContent:'start', flexDirection:'column', height:'70vh'}}>
                    <Spacer height={50}/>
                    <AccountLogin email={null}/>
                </Container>

                <Footer />

            </main>

        </>
    )
}

export default LoginPage;
