import {CircularProgress, Container, Link, TextField, Typography} from "@mui/material";
import {DarkButton, LightButton} from "./buttons";
import Spacer from "./Spacer";
import {determineLocation} from "../assets/determineLocation";
import React, {ChangeEvent, useState} from "react";
import ErrorMessage from "./error";
import {useNavigate} from "react-router-dom";
import Cookies from 'universal-cookie';
import {login, postFetch, validateTokenExpiration} from "../api/client";
import withReactContent, {ReactSweetAlertOptions} from "sweetalert2-react-content";
import Swal from "sweetalert2";
import RenewalStore from "../stores/renewal-store";

export interface AccountLoginProps {
    email: string | null;
}

export function AccountLogin(props: AccountLoginProps) {
    const {email} = props;
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState(email ?? "");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)

    const handleUsernameChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setUsername((event.target as HTMLInputElement).value);
        if(errorMessage != ""){
            setErrorMessage("");
        }
    };

    const handlePasswordChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setPassword((event.target as HTMLInputElement).value);
        if(errorMessage != ""){
            setErrorMessage("");
        }
    };

    const handleLogin = () => {
        if(username == ""){
            setErrorMessage("Please enter a username");
            return;
        }
        if(password == ""){
            setErrorMessage("Please enter a password");
            return;
        }
        setLoading(true);

        login(username, password).then(([status, response])=>{
            if (status == 200) {
                navigate('/dashboard');
            } else {
                setLoading(false)
                console.log(response);
                setErrorMessage(response.message);
            }
        });
    }

    function handleForgotPassword(){
        const options: ReactSweetAlertOptions = {
            title: `Reset Password`,
            html: <>
                <Container disableGutters sx={{}}>
                    <TextField type={"email"} id={"reset-email"} fullWidth label={'Email'} margin="normal" variant="outlined"/>
                </Container>
            </>,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Reset Password",
            showCloseButton: true,
        }
        MySwal.fire(options).then(async (result) => {
            if (result.isConfirmed) {
                await postFetch({
                    endpoint: "api/auth/resetPassword",
                    body: {"email": (document.getElementById("reset-email") as HTMLInputElement)!.value},
                    authenticate: false
                }).then(() => {
                    MySwal.fire({
                        icon: "success",
                        text: "An email has been sent to reset your password"
                    })
                })
            }

        });
    }

    return (
        <>
            <Typography variant={"h4"} sx={{fontWeight: 300}}>User Login</Typography>
            <Spacer height={20}/>
            <Container disableGutters maxWidth={"xs"} sx={{display: 'flex', flexDirection: 'column', mx: 0}}>
                <TextField disabled={email != null} label="Username" variant="outlined" value={username} onChange={handleUsernameChange} />
                <TextField label="Enter Password" variant="outlined" type={'password'} sx={{my: 2, mb: 1}} value={password} onChange={handlePasswordChange}/>
                <Link onClick={handleForgotPassword} sx={{fontWeight: 500}}>Forgot My Password</Link>
                {/*<DarkButton disabled={loading} onClick={handleCancel} variant={'contained'}*/}
                {/*            sx={{p: 2, mt: 3}}>{(loading ? <CircularProgress size={20}/> : "Cancel Order")}</DarkButton>*/}
                <ErrorMessage message={errorMessage}/>
                <LightButton disabled={loading} onClick={handleLogin} variant={'contained'}
                             sx={{p: 2, mt: 3, width: '100%'}}>{(loading ?
                    <CircularProgress size={20}/> : "Log In")}</LightButton>
            </Container>
        </>
    );
}