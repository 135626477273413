import React, {ChangeEvent, useEffect, useState} from 'react'
import {main, lightGrey} from "../../assets/colors";
import Typography from "@mui/material/Typography";
import {Button, ButtonProps, Container, TextField} from "@mui/material";
import Spacer from "../Spacer";
import {styled} from "@mui/material/styles";
import ErrorMessage from "../error";

const Newsletter = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleEmailChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setEmail((event.target as HTMLInputElement).value);
    };

    const NewsletterButton = styled(Button)<ButtonProps>(({theme}) => ({
        color: 'white',
        backgroundColor: main,
        '&:hover': {
            backgroundColor: 'white'[700],
        },
    }));

    return (
        <>
            <section>
                <Container maxWidth={false} disableGutters
                           sx={{
                               background:lightGrey,
                               position: 'relative',
                               py: 5,
                               px: {xs: 0, sm: 5, md: 10},
                               display: 'flex',
                               alignItems: 'center',
                               flexDirection: 'column',
                           }}>
                    <Typography sx={{
                        textAlign: "center",
                        p: 0,
                        fontSize: {xs: '1.2rem', md: '2.4rem'},
                        fontWeight: 'normal',
                        color: main,
                    }}>Sign-Up for Our Newsletter</Typography>
                    <Typography sx={{textAlign:'center'}}>to receive email updates on new product announcements, best corporate practices,
                        tips and more.</Typography>
                    <Spacer height={30}/>
                    <TextField disabled={message!== ""} placeholder={"Enter Email Address"} value={email} onChange={handleEmailChange}></TextField>
                    <Spacer height={20}/>
                    <NewsletterButton disabled={message!==""} variant={'contained'}
                                      onClick={()=>setMessage("Thank you.")}>Join Newsletter</NewsletterButton>
                    <ErrorMessage message={message}/>

                </Container>
            </section>

        </>
    )
}

export default Newsletter;
