import {CircularProgress, Container, TextField, Typography} from "@mui/material";
import {LightButton} from "./buttons";
import Spacer from "./Spacer";
import React, {ChangeEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createAccount} from "../api/client";
import ErrorMessage from "./error";

export interface AccountCreateProps {
    email: string | null;
}

export function AccountCreate(props: AccountCreateProps) {
    const {email} = props;
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState(email ?? "");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleUsernameChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setUsername((event.target as HTMLInputElement).value);
        if(errorMessage != ""){
            setErrorMessage("");
        }
    };

    const handlePasswordChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setPassword((event.target as HTMLInputElement).value);
        if(errorMessage != ""){
            setErrorMessage("");
        }
    };

    const handleConfirmPasswordChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword((event.target as HTMLInputElement).value);
        if(errorMessage != ""){
            setErrorMessage("");
        }
    };

    const handleAccountCreate = () => {
        if(username == ""){
            setErrorMessage("Please enter a username");
            return;
        }
        if(password == ""){
            setErrorMessage("Please enter a password");
            return;
        }
        if(password !== confirmPassword){
            setErrorMessage("Passwords must be the same");
            return;
        }
        setLoading(true);

        createAccount(username, password).then(([status, response])=>{
            if (status == 201) {
                navigate('/dashboard');
                // setLoading(false)
                // console.log(response);
                // setErrorMessage("Go check your email");
            } else {
                setLoading(false)
                console.log(response);
                setErrorMessage(response.message);
            }
        });
    }
    return (
        <>
            <Typography variant={"h4"} sx={{fontWeight: 300}}>Create account password</Typography>
            <Spacer height={10}/>
            <Typography sx={{fontWeight: 500}}>Enter a password to create your account to access past filings and manage
                your account.</Typography>
            <Spacer height={20}/>
            <Container disableGutters maxWidth={"xs"} sx={{display: 'flex', flexDirection: 'column', mx: 0}}>
                <TextField disabled={email != null} label="Username" variant="outlined" value={username} onChange={handleUsernameChange} />
                <TextField label="Enter Password" variant="outlined" type={'password'} sx={{my: 2, mb: 1}} value={password} onChange={handlePasswordChange}/>
                <TextField label="Confirm Password" variant="outlined" type={'password'} value={confirmPassword} onChange={handleConfirmPasswordChange}/>

                <Spacer height={20}/>
                {/*<LightButton variant={'contained'} sx={{p: 2, mt: 3, width: '100%'}}>Create Account</LightButton>*/}
                <ErrorMessage message={errorMessage}/>
                <LightButton disabled={loading} onClick={handleAccountCreate} variant={'contained'}
                             sx={{p: 2, mt: 3, width: '100%'}}>{(loading ?
                    <CircularProgress size={20}/> : "Create Account")}</LightButton>
            </Container>
        </>
    );
}