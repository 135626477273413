import withReactContent, {ReactSweetAlertOptions} from "sweetalert2-react-content";
import Swal from "sweetalert2";
import React, {ChangeEvent, SyntheticEvent, useState} from "react";
import RenewalStore from "../../stores/renewal-store";
import {Autocomplete, Container, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import ChangeFeinComponent from "./change-fein-component";
import {similarity} from "../../assets/helpers";

function editButtonAction({field, index}: { field: string, index?: number }) {
    // console.log("edit button action");
    let formState = {};
    // console.log(formState);
    const MySwal = withReactContent(Swal)

    const manageFormState = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>|SyntheticEvent, fieldName: string) => {
        // console.log("form state managed");
        if (fieldName === "fein") {
            switch ((event.target as HTMLInputElement).value) {
                case "applied":
                    formState = "Applied For"
                    break
                case "na":
                    formState = "Not Applicable"
                    break
                case "provided":
                    formState = ""
                    break
                default:
                    formState = (event.target as HTMLInputElement).value;
            }
        } else if(fieldName==="titleName") {
            formState[fieldName] = (event.target as HTMLInputElement).textContent;
        }else {
            formState[fieldName] = (event.target as HTMLInputElement).value;
        }
        console.log(formState);
        Swal.resetValidationMessage();
    }

    const validateForm = (data: { fieldName: string; errorMessage: string }[]) => {
        data.reverse().forEach((singleCell) => {
            if (singleCell.fieldName === "fein") {
                console.log("fein");
                if (formState === undefined) {
                    Swal.showValidationMessage(
                        singleCell.errorMessage
                    )
                } else {
                    let val = formState.toString().trim();
                    console.log(val);
                    if (val === "Applied For" || val === "Not Applicable") {
                        //do nothing
                    } else {
                        const Regex = new RegExp('^[0-9]{2}-[0-9]{7}$', 'g');

                        const found = Regex.test(val);
                        if (!found) {
                            Swal.showValidationMessage(singleCell.errorMessage);
                        }
                    }

                }

            } else if (singleCell.fieldName === "registeredAgent") {
                // console.log("RA");
                //If 99% of letters match ignoring punctuation and spaces..do not allow.
                let val = formState["firstName"];
                // console.log(formState);
                // console.log(val);
                if (val === undefined) {
                    Swal.showValidationMessage(
                        singleCell.errorMessage
                    )
                } else {
                    let pattern = /[^A-Za-z0-9]|(LLC)|(L.L.C.)|(INCORPORATED)|(INC)|(COMPANY)|(CORPORATION)|(CORP)|(LIMITED LIABILITY COMPANY)|(LIMITED LIABILITY CO)|(LIMITED PARTNERSHIP)|(LP)|(LTD)|(CO)+/gi;
                    let valLetters = val.replace(pattern, "")
                    let companyName = RenewalStore.getUpdatedCompanyInfo.name
                    let companyLetters = companyName.replace(pattern, "");
                    // console.log(similarity(valLetters, companyLetters));
                    if (similarity(valLetters, companyLetters) > 0.8) {
                        Swal.showValidationMessage(
                            singleCell.errorMessage
                        )
                    }
                }
            } else {
                if (formState[singleCell.fieldName] === undefined || formState[singleCell.fieldName].trim() == "") {
                    Swal.showValidationMessage(
                        singleCell.errorMessage
                    )
                }
            }

        })
    }

    if (field == "principal") {
        formState = JSON.parse(JSON.stringify(RenewalStore.getUpdatedCompanyInfo.principalAddress));
        const options: ReactSweetAlertOptions = {
            title: `Change Principal Address`,
            html: <>
                <Container disableGutters sx={{}}>
                    <TextField id={"street1"} fullWidth label={'Address 1'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.principalAddress.street1}`}
                               onChange={(event) => {
                                   manageFormState(event, 'street1')
                               }}/>
                    <TextField id={"street2"} fullWidth label={'Address 2'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.principalAddress.street2 ?? ''}`}
                               onChange={(event) => {
                                   manageFormState(event, 'street2')
                               }}/>
                    <TextField id={"city"} fullWidth label={'City'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.principalAddress.city}`}
                               onChange={(event) => {
                                   manageFormState(event, 'city')
                               }}/>
                    <TextField id={"state"} fullWidth label={'State'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.principalAddress.state}`}
                               onChange={(event) => {
                                   manageFormState(event, 'state')
                               }}/>
                    <TextField id={"zipCode"} fullWidth label={'Zip Code'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.principalAddress.zipCode}`}
                               onChange={(event) => {
                                   manageFormState(event, 'zipCode')
                               }}/>
                </Container>
            </>,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Change",
            showCloseButton: true,
            preConfirm: () => validateForm([
                {fieldName: 'street1', errorMessage: 'You need to enter an address'},
                {fieldName: 'city', errorMessage: 'You need to enter a city'},
                {fieldName: 'state', errorMessage: 'You need to enter a state'},
                {fieldName: 'zipCode', errorMessage: 'You need to enter a zip code'}
            ]),
        }
        MySwal.fire(options).then((result) => {
            if (result.isConfirmed) {
                RenewalStore.replaceUpdatedCompanyInfoField('principalAddress', formState);
            }

        });
    }
    else if (field == "mailing") {
        formState = JSON.parse(JSON.stringify(RenewalStore.getUpdatedCompanyInfo.mailingAddress));
        const options: ReactSweetAlertOptions = {
            title: `Change Mailing Address`,
            html: <>
                <Container disableGutters sx={{}}>
                    <TextField fullWidth label={'Address 1'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.mailingAddress.street1}`}
                               onChange={(event) => {
                                   manageFormState(event, 'street1')
                               }}/>
                    <TextField fullWidth label={'Address 2'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.mailingAddress.street2 ?? ''}`}
                               onChange={(event) => {
                                   manageFormState(event, 'street2')
                               }}/>
                    <TextField fullWidth label={'City'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.mailingAddress.city}`}
                               onChange={(event) => {
                                   manageFormState(event, 'city')
                               }}/>
                    <TextField fullWidth label={'State'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.mailingAddress.state}`}
                               onChange={(event) => {
                                   manageFormState(event, 'state')
                               }}/>
                    <TextField fullWidth label={'Zip Code'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.mailingAddress.zipCode}`}
                               onChange={(event) => {
                                   manageFormState(event, 'zipCode')
                               }}/>
                </Container>
            </>,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Change",
            showCloseButton: true,
            preConfirm: () => validateForm([
                {fieldName: 'street1', errorMessage: 'You need to enter an address'},
                {fieldName: 'city', errorMessage: 'You need to enter a city'},
                {fieldName: 'state', errorMessage: 'You need to enter a state'},
                {fieldName: 'zipCode', errorMessage: 'You need to enter a zip code'}
            ]),
        }
        MySwal.fire(options).then((result) => {
            if (result.isConfirmed) {
                RenewalStore.replaceUpdatedCompanyInfoField('mailingAddress', formState);
            }

        });
    }
    else if (field === "registered_agent") {
        formState = JSON.parse(JSON.stringify(RenewalStore.getUpdatedCompanyInfo.registeredAgent));
        const options: ReactSweetAlertOptions = {
            title: `Change Registered Agent`,
            html: <>
                <Container disableGutters sx={{}}>
                    <TextField fullWidth label={'Name'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.registeredAgent.firstName}`}
                               onChange={(event) => {
                                   manageFormState(event, 'firstName')
                               }}/>
                    <TextField fullWidth label={'Address'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.registeredAgent.address}`}
                               onChange={(event) => {
                                   manageFormState(event, 'address')
                               }}/>
                    <TextField fullWidth label={'City'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.registeredAgent.city}`}
                               onChange={(event) => {
                                   manageFormState(event, 'city')
                               }}/>
                    <TextField fullWidth disabled label={'State'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.registeredAgent.state}`}
                               onChange={(event) => {
                                   manageFormState(event, 'state')
                               }}/>
                    <TextField fullWidth label={'Zip Code'} margin="normal" variant="outlined"
                               defaultValue={`${RenewalStore.getUpdatedCompanyInfo.registeredAgent.zipCode}`}
                               onChange={(event) => {
                                   manageFormState(event, 'zipCode')
                               }}/>
                </Container>
            </>,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Change",
            showCloseButton: true,
            preConfirm: () => validateForm([
                {fieldName: 'firstName', errorMessage: 'You need to enter a name'},
                {fieldName: 'address', errorMessage: 'You need to enter an address'},
                {fieldName: 'city', errorMessage: 'You need to enter a city'},
                {fieldName: 'state', errorMessage: 'You need to enter a state'},
                {fieldName: 'zipCode', errorMessage: 'You need to enter a zip code'},
                {fieldName: 'registeredAgent', errorMessage: 'Registered Agent cannot be the same as the company'}
            ]),
        }
        MySwal.fire(options).then((result) => {
            if (result.isConfirmed) {
                RenewalStore.replaceUpdatedCompanyInfoField('registeredAgent', formState);
            }

        });
    }
    else if (field.includes("officer")) {
        // formState = JSON.parse(JSON.stringify(RenewalStore.getUpdatedCompanyInfo[field]));
        if (RenewalStore.getUpdatedCompanyInfo.officers[index!] !== undefined) {
            formState = JSON.parse(JSON.stringify(RenewalStore.getUpdatedCompanyInfo.officers[index!]));
        }
        const options: ReactSweetAlertOptions = {
            title: Object.keys(formState).length === 0 ? `New Officer` : `Change Officer`,
            html: <>
                <Container disableGutters sx={{}}>
                    <TextField fullWidth label={'Title'} margin="normal" variant="outlined"
                               defaultValue={`${formState['title'] ?? ""}`}
                               onChange={(event) => {
                                   manageFormState(event, 'title')
                               }}/>
                    <TextField fullWidth label={'First Name'} margin="normal" variant="outlined"
                               defaultValue={`${formState['firstName'] ?? ""}`}
                               onChange={(event) => {
                                   manageFormState(event, 'firstName')
                               }}/>
                    <TextField fullWidth label={'Last Name'} margin="normal" variant="outlined"
                               defaultValue={`${formState['lastName'] ?? ""}`}
                               onChange={(event) => {
                                   manageFormState(event, 'lastName')
                               }}/>
                    <TextField fullWidth label={'Middle Name'} margin="normal" variant="outlined"
                               defaultValue={`${formState['middleName'] ?? ""}`}
                               onChange={(event) => {
                                   manageFormState(event, 'middleName')
                               }}/>
                    <Autocomplete
                        sx={{mt: 2, mb: 1,}}
                        freeSolo
                        disablePortal
                        id="combo-box-demo"
                        options={["Sr.", "Jr.", "II", "III", "IV", "Esq.", "Dr.", "Phd"]}
                        defaultValue={formState['titleName'] ?? ""}
                        renderInput={(params) => <TextField {...params} label="Title Name"/>}
                        onChange={(event) => {
                            manageFormState(event, 'titleName')
                        }}
                    />
                    <TextField fullWidth label={'Address'} margin="normal" variant="outlined"
                               defaultValue={`${formState['address'] ?? ""}`}
                               onChange={(event) => {
                                   manageFormState(event, 'address')
                               }}/>
                    <TextField fullWidth label={'City'} margin="normal" variant="outlined"
                               defaultValue={`${formState['city'] ?? ""}`}
                               onChange={(event) => {
                                   manageFormState(event, 'city')
                               }}/>
                    <TextField fullWidth label={'State'} margin="normal" variant="outlined"
                               defaultValue={`${formState['state'] ?? ""}`}
                               onChange={(event) => {
                                   manageFormState(event, 'state')
                               }}/>
                    <TextField fullWidth label={'Zip Code'} margin="normal" variant="outlined"
                               defaultValue={`${formState['zipCode'] ?? ""}`}
                               onChange={(event) => {
                                   manageFormState(event, 'zipCode')
                               }}/>
                </Container>
            </>,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: Object.keys(formState).length === 0 ? "Add" : "Change",
            showCloseButton: true,
            preConfirm: () => validateForm([
                {fieldName: 'title', errorMessage: 'You need to enter a title for this officer'},
                {fieldName: 'firstName', errorMessage: 'You need to enter a name'},
                {fieldName: 'address', errorMessage: 'You need to enter an address'},
                {fieldName: 'city', errorMessage: 'You need to enter a city'},
                {fieldName: 'state', errorMessage: 'You need to enter a state'},
                {fieldName: 'zipCode', errorMessage: 'You need to enter a zip code'}
            ]),
        }
        MySwal.fire(options).then((result) => {
            if (result.isConfirmed) {
                console.log("replacing!");
                // RenewalStore.replaceUpdatedCompanyInfoField(field, formState);
                RenewalStore.replaceUpdatedCompanyInfoOfficer(index!, formState);
            }

        });
    }
    else if (field == "ein") {
        formState = RenewalStore.getUpdatedCompanyInfo.fein;
        console.log(formState);
        const options: ReactSweetAlertOptions = {
            title: `Change FEI/EIN Number`,
            html: <>
                <Container disableGutters sx={{}}>
                    <ChangeFeinComponent manageFormState={manageFormState}/>
                </Container>
            </>,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Change",
            showCloseButton: true,
            preConfirm: () => validateForm([
                {fieldName: 'fein', errorMessage: 'Please enter a valid EIN in format xx-xxxxxxx'},
            ]),
        }
        MySwal.fire(options).then((result) => {
            if (result.isConfirmed) {
                RenewalStore.replaceUpdatedCompanyInfoField('fein', formState);
            }

        });
    }
}

export default editButtonAction