// import { useErrorStatus } from './ErrorHandler';

import {useEffect, useState} from "react";
import {determineLocation} from "../assets/determineLocation";
import {differenceInSeconds} from 'date-fns'
import jwt_decode, {JwtPayload} from "jwt-decode";
import {checkTokenValid} from "./token";
import store from "../store";
import Swal from 'sweetalert2'
import Store from "../store";

export async function validateTokenExpiration() {
    let now = new Date();
    // console.log("ahh");
    // console.log(localStorage.getItem('token'));
    if (localStorage.getItem('token') == null || localStorage.getItem('token') == '') {
        return false;
    }

    if (localStorage.getItem('token_expiration') == null) {
        localStorage.setItem('token', '');
        localStorage.setItem('token_expiration', now.getTime().toString());
    }

    if (!checkTokenValid()) {
        // console.log("We need a new token");
        if(!store.getGettingRefreshToken){
            store.setGettingRefreshToken(true);
            return await refreshToken();
        }

    }

    return true;


}

function setTokens(x: any) {
    let decodedToken = jwt_decode<JwtPayload>(x.token);
    localStorage.setItem('token', x.token);
    localStorage.setItem('token_expiration', decodedToken.exp!.toString());
    localStorage.setItem('refresh_token', x.refresh_token);
    localStorage.setItem('refresh_token_expiration', x.refresh_token_expiration);
}

function removeTokens() {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expiration');
}

async function refreshToken() {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({'refresh_token': localStorage.getItem('refresh_token')})
    };

    let response = await fetch(`${determineLocation()}/api/auth/refresh`, requestOptions);
    if(response.status == 200){
        await response.json().then((x) => {
            setTokens(x);
        })
        store.setGettingRefreshToken(false);
        return true;
    }

    store.setGettingRefreshToken(false);
    return false;

}

export async function login(username: string, password: string) {
    let response = await postFetch({
        endpoint: "api/auth/login",
        body: {username: username, password: password},
        authenticate: false
    });
    let responseJson = await response.json();
    if (response.status == 200) {
        setTokens(responseJson);
    }

    return [response.status, responseJson];
}

export async function createAccount(username: string, password: string){
    let response = await postFetch({
        endpoint: "api/auth/signup",
        body: {username: username, password: password},
        authenticate: false
    });
    let responseJson = await response.json();
    if (response.status == 201) {
        setTokens(responseJson);
    }

    return [response.status, responseJson];
}

export async function changePassword(token: string, password: string){
    let response = await postFetch({
        endpoint: "api/auth/changePassword",
        body: {token: token, password: password},
        authenticate: false
    });
    let responseJson = await response.json();
    if (response.status == 201) {
        setTokens(responseJson);
    }

    return [response.status, responseJson];
}

export async function logout() {
    Swal.fire({
        didOpen: () => {
            Swal.showLoading(null);
        }
    });
    return postFetch({endpoint:"api/auth/logout", body: {refresh_token:localStorage.getItem('refresh_token')}}).then((x)=>{
        removeTokens();
        Swal.close();
        store.isLoggedIn;
    })

}

export async function postFetch({
                             endpoint,
                             body = null,
                             authenticate = true
                         }: { endpoint: string, body?: null | any, authenticate?: null | boolean }) {


    let generatedHeaders;
    if (authenticate) {
        await validateTokenExpiration();
        generatedHeaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
    } else {
        generatedHeaders = {'Content-Type': 'application/json'};
    }

    const requestOptions = {
        method: 'POST',
        headers: generatedHeaders,
        body: JSON.stringify(body)
    };
    return fetch(`${determineLocation()}/${endpoint}`, requestOptions)
}

export async function getFetch({
                                    endpoint,
                                    authenticate = true
                                }: { endpoint: string, authenticate?: null | boolean }) {
    await validateTokenExpiration();

    let generatedHeaders;
    if (authenticate) {
        await Store.getLoggedIn();
        generatedHeaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
    } else {
        generatedHeaders = {'Content-Type': 'application/json'};
    }

    const requestOptions = {
        method: 'GET',
        headers: generatedHeaders
    };
    return fetch(`${determineLocation()}/${endpoint}`, requestOptions)
}