import {observer} from "mobx-react";
import React, {ChangeEvent, useEffect, useState} from "react";
import OfficersList from "./corporate/officers";
import Store from "../store";
import ErrorMessage from "./error";
import {Checkbox, Container, FormControlLabel, FormGroup, TextareaAutosize, TextField, Typography} from "@mui/material";
import {DarkButton, InlineButton, LightButton} from "./buttons";
import {SignatureDialog} from "./signature-dialog";
import {FortressDivider} from "./fortress-divider";
import RenewalStore from "../stores/renewal-store";
import editButtonAction from "../ui/renewal/renewal-edit-button-action";
import diff from "recursive-diff";
import CheckIcon from '@mui/icons-material/Check';
import Swal from "sweetalert2";

const TERMS_OF_SERVICE = `I certify that the above information on this form is true and correct to the best of my knowledge and belief; that my electronic signature is the equivalent of a handwritten signature; that I am authorized to complete this report on behalf of the company and/or individual(s) listed above; and that I am authorizing Fortress Compliance LLC, or any of its subsidiaries, to execute this annual report with the Department of State on my behalf.\n\nIMPORTANT NOTICE: You are NOT required to purchase anything from this company and the company is NOT affiliated, endorsed, or approved by any governmental entity. The item offered in this advertisement has NOT been approved or endorsed by any governmental agency, and this offer is NOT being made by an agency of the government.`

const RenewStepOne = observer(({companyInfo}: { companyInfo: any }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [termsChecked, setTermsChecked] = React.useState(false);

    const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTermsChecked(event.target.checked);
    };

    //Signature Things
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
        const delta = diff.getDiff(JSON.parse(JSON.stringify(RenewalStore.getCompanyInfo)), JSON.parse(JSON.stringify(RenewalStore.getUpdatedCompanyInfo)));
        console.log(delta);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <FortressDivider textAlign="left" sx={{mb: 1}}>Company Details</FortressDivider>

            <Container disableGutters sx={{mb: 3}}>
                <TextField disabled fullWidth label="Company Name" variant="outlined" value={companyInfo.name}
                           sx={{color: 'black'}}/>
            </Container>

            <Container disableGutters sx={{mb: 3}}>
                <TextField disabled fullWidth label={
                    <>
                        <span>FEI/EIN Number</span>
                        <InlineButton onClick={() => editButtonAction({field: "ein"})}>Change</InlineButton>
                    </>
                } variant="outlined" placeholder="Please Adjust EIN" value={RenewalStore.getUpdatedCompanyInfo.fein ?? "Not Applicable"}
                           sx={{color: 'black'}}/>
            </Container>

            <FortressDivider textAlign="left">Address Details</FortressDivider>

            <Container disableGutters sx={{mb: 3, display: 'flex'}}>
                <Container disableGutters sx={{pr: {xs: 1, md: 3}}}>
                    <TextField disabled fullWidth
                               label={<Container disableGutters sx={{display: 'flex', justifyContent: 'space-between'}}>
                                   <span>Principal Address</span>
                                   <InlineButton
                                       onClick={() => editButtonAction({field: "principal"})}>Change</InlineButton>
                               </Container>} margin="normal" variant="outlined"
                               value={`${RenewalStore.getUpdatedCompanyInfo.principalAddress.street1}${RenewalStore.getUpdatedCompanyInfo.principalAddress.street2 ? `, ${RenewalStore.getUpdatedCompanyInfo.principalAddress.street2}` : ''}`}/>
                    <TextField disabled fullWidth variant="outlined"
                               value={`${RenewalStore.getUpdatedCompanyInfo.principalAddress.city}, ${RenewalStore.getUpdatedCompanyInfo.principalAddress.state} ${RenewalStore.getUpdatedCompanyInfo.principalAddress.zipCode}`}/>
                </Container>
                <Container disableGutters sx={{pl: {xs: 1, md: 3}}}>
                    <TextField disabled fullWidth label={
                        <>
                            <span>Mailing Address</span>
                            <InlineButton onClick={() => editButtonAction({field: "mailing"})}>Change</InlineButton>
                        </>
                    } margin="normal" variant="outlined"
                               value={`${RenewalStore.getUpdatedCompanyInfo.mailingAddress.street1}${RenewalStore.getUpdatedCompanyInfo.mailingAddress.street2 ? `, ${RenewalStore.getUpdatedCompanyInfo.mailingAddress.street2}` : ''}`}/>
                    <TextField disabled fullWidth variant="outlined"
                               value={`${RenewalStore.getUpdatedCompanyInfo.mailingAddress.city}, ${RenewalStore.getUpdatedCompanyInfo.mailingAddress.state} ${RenewalStore.getUpdatedCompanyInfo.mailingAddress.zipCode}`}/>
                </Container>
            </Container>

            <FortressDivider textAlign="left">Registered Agent Details</FortressDivider>

            <Container disableGutters sx={{mb: 3, display: 'flex'}}>
                <Container disableGutters sx={{}}>
                    <TextField disabled fullWidth
                               label={<Container disableGutters sx={{display: 'flex', justifyContent: 'space-between'}}>
                                   <span>Registered Agent</span>
                                   <InlineButton
                                       onClick={() => editButtonAction({field: "registered_agent"})}>Change</InlineButton>
                               </Container>} margin="normal" variant="outlined"
                               value={`${RenewalStore.getUpdatedCompanyInfo.registeredAgent.firstName}`}/>
                    <TextField disabled fullWidth variant="outlined"
                               value={`${RenewalStore.getUpdatedCompanyInfo.registeredAgent.address}`}/>
                    <TextField disabled fullWidth margin='dense' variant="outlined"
                               value={`${RenewalStore.getUpdatedCompanyInfo.registeredAgent.city}, ${RenewalStore.getUpdatedCompanyInfo.registeredAgent.state} ${RenewalStore.getUpdatedCompanyInfo.registeredAgent.zipCode}`}/>
                </Container>
            </Container>

            <FortressDivider textAlign="left" sx={{mb: 2}}>Officer/Director Details</FortressDivider>

            <OfficersList officers={companyInfo.officers}/>
            {RenewalStore.getUpdatedCompanyInfo.officers.length < 6 ? <DarkButton onClick={() => editButtonAction({field:"officer", index:RenewalStore.getUpdatedCompanyInfo.officers.length})}>Add New Officer</DarkButton> : null}

            <FortressDivider textAlign="left" sx={{mb: 1}}>Terms of Service</FortressDivider>
            <TextareaAutosize style={{width: '96%'}} maxRows={5} className={'termsBox'}
                              value={TERMS_OF_SERVICE}/>

            {/*<FormGroup sx={{alignItems: 'end'}}>*/}
            {/*    <FormControlLabel control={<Checkbox checked={termsChecked} onChange={handleTermsChange}/>}*/}
            {/*                      label="I have read and accept"/>*/}
            {/*</FormGroup>*/}

            <ErrorMessage message={errorMessage}/>

            <Container maxWidth={'sm'} sx={{display: 'flex', flexDirection: 'column'}}>
                <DarkButton disabled={termsChecked} onClick={()=>{
                    if(RenewalStore.getUpdatedCompanyInfo.officers.length > 0){
                        setTermsChecked(true)
                    }else{
                        Swal.fire(
                            "You must have at least one officer on your annual report."
                        )
                    }
                }}
                            sx={{p: 2, mt: 3}} disableElevation
                            variant={'contained'}>{termsChecked ?
                    <><CheckIcon/><Typography> I have read and accept</Typography></>
                    : "1. Click Here To Accept Terms of Service" } </DarkButton>
                <DarkButton disabled={Store.getSignatureHash != '' || !termsChecked} onClick={handleClickOpen} sx={{p: 2, mt: 3}}
                             variant={'contained'}>
                    {Store.getSignatureHash == '' ? '2. Click To Add Digital Signature' : Store.getSignatureHash}
                </DarkButton>
                <DarkButton disabled={!termsChecked || (Store.getSignatureHash == '')} onClick={setNextStep}
                            sx={{p: 2, mt: 3}} disableElevation
                            variant={'contained'}>3. Proceed To Payment</DarkButton>
            </Container>

            <SignatureDialog
                open={open}
                onClose={handleClose}
                officers={RenewalStore.getUpdatedCompanyInfo.officers}
                documentNumber={RenewalStore.getCompanyInfo['id']}
            />

        </>
    )


    function setNextStep() {
        if (!termsChecked) {
            setErrorMessage('Please read and accept the terms of service');
            return;
        }

        if (Store.getSignatureHash == '') {
            setErrorMessage('Please digitally sign before you continue');
            return;
        }

        if (Store.getSelectedOfficer !== null) {
            Store.setRenewStep(2)
        } else {
            setErrorMessage('You must sign before you can proceed.');
        }
    }
})


export default RenewStepOne