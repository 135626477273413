import React, {useEffect, useState} from 'react'
import Typography from "@mui/material/Typography";
import {main} from "../../assets/colors";
import CustomizedAccordions from "../faq-accordion";
import {Button, ButtonProps, Container} from "@mui/material";
import Spacer from "../Spacer";
import {styled} from "@mui/material/styles";

const Contact = () => {

    const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
        color: main,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: main[700],
        },
    }));

    function mailTo(e) {
        window.location.href = "mailto:support@fcfiling.com";
        e.preventDefault();
    }

    return (
        <>
            <section>
                <div className="anchor" id="Contact"/>
                <Container maxWidth={false} disableGutters
                           sx={{
                               position: 'relative',
                               py: 5,
                               px: {xs: 0, sm: 5, md: 10},
                               background: main,
                               minHeight:'60vh',
                               display:'flex',
                               alignItems:'center',
                               justifyContent:'center',
                               flexDirection:'column',
                           }}>
                    <Typography sx={{
                        textAlign: "center",
                        p: 3,
                        fontSize: {xs: '1.2rem', md: '2.4rem'},
                        fontWeight: 'normal',
                        color: 'white',
                    }}>We're here to answer your questions</Typography>

                    <Container sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <Container sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'end',
                            flexDirection: 'column',
                            width: 'fit-content'
                        }}>
                            <Typography sx={{width: 'fit-content',fontSize: {xs: '1.2rem', md: '1.5rem'},}}>Monday: 9:00am - 5:00pm EST</Typography>
                            <Typography sx={{width: 'fit-content',fontSize: {xs: '1.2rem', md: '1.5rem'},}}>Tuesday: 9:00am - 5:00pm EST</Typography>
                            <Typography sx={{width: 'fit-content',fontSize: {xs: '1.2rem', md: '1.5rem'},}}>Wednesday: 9:00am - 5:00pm EST</Typography>
                            <Typography sx={{width: 'fit-content',fontSize: {xs: '1.2rem', md: '1.5rem'},}}>Thursday: 9:00am - 5:00pm EST</Typography>
                            <Typography sx={{width: 'fit-content',fontSize: {xs: '1.2rem', md: '1.5rem'},}}>Friday: 9:00am - 5:00pm EST</Typography>
                        </Container>
                        <Spacer height={30}/>
                        <ColorButton onClick={(e) => mailTo(e)} variant={'contained'}>Contact Support</ColorButton>
                        <Typography sx={{fontSize: {xs: '1.2rem', md: '1.5rem'},}} onClick={(e) => mailTo(e)}>support@fcfiling.com</Typography>

                    </Container>
                </Container>
            </section>

        </>
    )
}

export default Contact;
