import React, {useEffect, useState} from "react";
import Loading from "./loading";
import {observer} from "mobx-react";
import Store from "../store";
import RenewStepOne from "./renew-step-one";
import RenewStepTwo from "./renew-step-two";
import ResponsiveAppBar from "./responsive-app-bar";
import Footer from "./home/Footer";
import {Container} from "@mui/material";
import Spacer from "./Spacer";
import {determineLocation} from "../assets/determineLocation";
import {RenewalStepFlowDisplay} from "./renewal-step-flow-display";
import RenewalStore from "../stores/renewal-store";
import {postFetch} from "../api/client";

const CompanyRenew = observer(({documentNumber}) => {
    const [loading, setLoading] = useState(true);
    const [companyInfo, setCompanyInfo] = useState(null);

    useEffect(() => {
        if (companyInfo === null && Store.getRenewStep == 1) {
            setLoading(true);
            fetch(`${determineLocation()}/company/${documentNumber}`)
                .then(x => x.json()
                    .then((body) => {
                        setLoading(false);
                        console.log(body);
                        setCompanyInfo(body.message);
                        RenewalStore.setCompanyInfo(body.message);
                        RenewalStore.setUpdatedCompanyInfo(body.message);
                        postFetch({endpoint: `billing/${documentNumber}`, authenticate:false});
                    })
                );
        }
    }, []);

    if (loading) {
        return <Loading/>
    }

    return (<>
        <ResponsiveAppBar showLogin={false}/>
        <Container maxWidth={"md"}>
            <Spacer height={50}/>
            <RenewalStepFlowDisplay/>
            <Spacer height={20}/>
            {analyzeRenewalStep(companyInfo)}
        </Container>
        <Spacer height={250}/>
        <Footer/>

    </>);

})

function analyzeRenewalStep(companyInfo) {
    switch (Store.getRenewStep) {
        case 1:
            return (
                <RenewStepOne companyInfo={companyInfo}/>
            )
        case 2:
            return (
                <RenewStepTwo companyInfo={companyInfo}/>
            )
        default:
            return (
                <div>Error Step</div>
            )
    }
}

export default CompanyRenew