import React, {useEffect, useState} from 'react'
import {main, background} from "../../assets/colors";
import {Card, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import folderIcon from "../../assets/folder.png";
import Spacer from "../Spacer";

const Services = () => {

    return (
        <>
            <section>
                <div className="anchor" id="Services"/>
                <Container maxWidth={false} sx={{pb:10}}>
                    <Container sx={{width: 200, height: 200, pt: 10}}>
                        <img src={folderIcon} style={{width: '100%'}}/>
                    </Container>
                    <Typography sx={{
                        color: main,
                        textAlign: "center",
                        p: 3,
                        mt:2,
                        fontSize: {xs: '1.8rem', md: '2.3rem'},
                        lineHeight: {xs: 2, md: 1},
                        fontWeight: 'medium'
                    }}>Fast. Secure. Easy.</Typography>
                    <Spacer height={50}/>
                    <Typography sx={{
                        color: main,
                        textAlign: "left",
                        p: 3,
                        fontSize: {xs: '1.5rem', md: '2rem'},
                        lineHeight: {xs: 1.2, md: 1},
                        fontWeight: '900'
                    }}>Directed Annual Report Service</Typography>
                    <Typography sx={{
                        color: main,
                        textAlign: "left",
                        p: 3,
                        fontSize: {xs: '1.2rem', md: '1.5rem'},
                        lineHeight: 1.3,
                        fontWeight: 'normal'
                    }}>Stay compliant according to your local state regulations using our Directed Annual Report
                        Service. We will send you annual reminders to confirm your information and we will handle
                        filing your report with the appropriate agency. Access your records at any time by logging
                        in to our secure portal and viewing your business profile. Chat with a live representative
                        with any questions regarding your filing needs.
                    </Typography>
                </Container>
                <Container maxWidth={false} disableGutters
                           sx={{
                               position: 'relative',
                               background: "linear-gradient(135deg, #009fdf, #006baf);",
                               py: 5,
                               px: {xs: 0, sm: 5, md: 10}
                           }}>
                    <Typography sx={{
                        textAlign: "center",
                        p: 3,
                        fontSize: {xs: '1.2rem', md: '2.4rem'},
                        fontWeight: 'normal',
                        color: 'white',
                    }}>Simple process, one flat fee.</Typography>
                    <Container sx={{display: 'flex', justifyContent: 'space-around'}}>
                        <Container maxWidth={false} sx={{
                            width: '50%',
                            background: "linear-gradient(135deg, #f5fbf2, #73c33e);",
                            height: 25,
                            position: 'absolute',
                            top: 'calc(50% - 12px)',
                            zIndex: '0'
                        }}></Container>

                        <ServiceCard number={"1"}
                                     text={"Confirm your corporate details and information via our secure portal."}/>
                        <ServiceCard number={"2"}
                                     text={"Enter payment information for our $99 flat service fee + local state/federal filing fees."}/>
                        <ServiceCard number={"3"}
                                     text={"Within 24-28 hours you will receive an email with a copy of your documents. Documents, once filed, can be accessed at any time via our secure portal."}/>
                    </Container>
                    <Typography sx={{
                        textAlign: "center",
                        p: 3,
                        fontWeight: 'normal',
                        color: 'white',
                        fontSize: {xs: '1.2rem', md: '1.5rem'},
                    }}>Have multiple corporations? Contact sales for volume discounting.</Typography>
                </Container>
            </section>

        </>
    )
}

const ServiceCard = ({text, number}: { text: string, number: string }) => {
    return (<Card sx={{width: '30%', maxWidth: {xs: 175, sm: 300}, height: {xs: 150, sm: 250}, zIndex: 1, boxShadow: 5}}>
        <Container className={"cutout"} sx={{
            background: main,
            height: "30%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Typography sx={{
                textAlign: "center",
                fontSize: {xs: '1rem', sm: '2rem'},
                color: 'white',
                fontWeight: '900',
                transform: 'skewY(365deg)',
            }}>{number}</Typography>
        </Container>
        <Typography sx={{
            textAlign: "center",
            fontSize: {xs: '0.6rem', sm: '0.8rem', md: '1.1rem', lg: '1.2rem'},
            p: {xs: 1, sm: 2},
        }}>{text}</Typography>
    </Card>);
}

export default Services;
