import './App.css'
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Footer from "./components/home/Footer";
import ResponsiveAppBarCombined from "./components/responsive-app-bar-combined";
import {Container, LinearProgress, Typography} from "@mui/material";
import {determineLocation} from "./assets/determineLocation";
import Spacer from "./components/Spacer";

const Activate = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("token");

    const [tokenFailure, setTokenFailure] = useState(false);

    React.useEffect(() => {
        if (token != null) {
            fetch(`${determineLocation()}/api/auth/auth-token/${token}`, {method: 'GET'})
                .then(x => {
                    if (x.status == 204) {
                        navigate(`/dashboard`)
                    } else {
                        setTokenFailure(true);
                    }
                });
        } else {
            navigate(`/`)
        }

    });

    return (
        <>
            {/*This does not update automatically when it changes, we should change it..and set this into the store to ensure we're updating all things.*/}
            <ResponsiveAppBarCombined showLogin={true}/>
            <main>
                <Container sx={{height: "100vh"}}>
                    <Typography align={"center"} sx={{marginTop: 5}}>
                        {tokenFailure ? <>
                                Token not found. Please contact support at <a
                                href='mailto:support@fcfiling.com'>support@fcfiling.com</a></> :
                            <>
                                <Typography>Checking Token...</Typography>
                                <Spacer height={10}/>
                                <LinearProgress/>
                            </>}
                    </Typography>
                </Container>
                <Footer/>
            </main>

        </>
    )
}

export default Activate;
